 /* eslint-disable */
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { authToken, NewDocRegDocument, PortfolioDocument } from 'app/fake-db/real-doc';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeleteDocumentService {
    doc: NewDocRegDocument;
    portfolioDoc: PortfolioDocument;
    authToken: string = sessionStorage.getItem('token');
    constructor(private http: HttpClient) { }

    deleteEnterpriseDocument(document: NewDocRegDocument): Observable<any> {
        const httpOptions: any = { 
            headers: new HttpHeaders ({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.authToken}`
            }),
        };
        httpOptions.params = new HttpParams().set('doc_id', document.id);
        return this.http.delete(`http://127.0.0.1:8080/doc-registry/api/v1/document/${document.id}`, httpOptions);
    }
    deletePortfolioDocument(document: PortfolioDocument): Observable<any> {
        const httpOptions = { 
            headers: new HttpHeaders ({
                'Content-Type': 'application/json',
                Authorization: `Bearer ${this.authToken}`
            })
        };
        return this.http.delete(`http://127.0.0.1:8080/doc-registry/api/v1/document/${document.id}`, httpOptions);
    }
}
