<div class="nav" [ngClass]="{'horizontal':layout === 'horizontal', 'vertical':layout === 'vertical'}">

 

    <!-- Horizontal Navigation Layout -->
    <ng-container>

        <ng-container *ngFor="let item of navigation">

            <nav-horizontal-collapsable *ngIf="item.type=='group'" [item]="item"></nav-horizontal-collapsable>
            <nav-horizontal-collapsable *ngIf="item.type=='collapsable'"
                                             [item]="item"></nav-horizontal-collapsable>
            <nav-horizontal-item *ngIf="item.type=='item'" [item]="item"></nav-horizontal-item>

        </ng-container>

    </ng-container>
    <!-- / Horizontal Navigation Layout -->

</div>
