 /* eslint-disable */
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppConfigService } from '@kav/services/config.service';
import { KavigationService } from '@kav/components/navigation/navigation.service';

import { FuseSplashScreenService } from '@kav/services/splash-screen.service';


import { demoNav } from 'app/navigation/navigation';


@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy
{
    title: 'SRay';
    AppConfig: any;
    navigation: any;

    // Private
    private _unsubscribeAll: Subject<any>;

  
    constructor(
        @Inject(DOCUMENT) private document: any,
        private _AppConfigService: AppConfigService,
        private _kavigationService: KavigationService,
        private _fuseSplashScreenService: FuseSplashScreenService, 
        private _platform: Platform
    )
    {
        // Get default navigation
        this.navigation = demoNav;

        // Register the navigation to the service
        this._kavigationService.register('main', this.navigation);

        // Set the main navigation as our current navigation
        this._kavigationService.setCurrentNavigation('main');

        
        // Add is-mobile class to the body if the platform is mobile
        if ( this._platform.ANDROID || this._platform.IOS )
        {
            this.document.body.classList.add('is-mobile');
        }

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Subscribe to config changes
        this._AppConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((config) => {

                this.AppConfig = config;

                // Boxed
                if ( this.AppConfig.layout.width === 'boxed' )
                {
                    this.document.body.classList.add('boxed');
                }
                else
                {
                    this.document.body.classList.remove('boxed');
                }

                // Color theme - Use normal for loop for IE11 compatibility
                // eslint-disable-next-line @typescript-eslint/prefer-for-of
                for ( let i = 0; i < this.document.body.classList.length; i++ )
                {
                    const className = this.document.body.classList[i];

                    if ( className.startsWith('theme-') )
                    {
                        this.document.body.classList.remove(className);
                    }
                }

                this.document.body.classList.add(this.AppConfig.colorTheme);
            });
 
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

 
}
