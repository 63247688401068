<!-- HORIZONTAL STEPPER EXAMPLE -->
<mat-horizontal-stepper  [linear]="true">
    <mat-step [stepControl]="formStep1">

        <form fxLayout="column" [formGroup]="formStep1">

            <ng-template matStepLabel>Create New Document</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Document name</mat-label>
                    <input matInput formControlName="name" >
                    <mat-error>Document  name is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" >
                    <mat-error>Description is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Client Name</mat-label>
                    <input matInput formControlName="clientName" required>
                    <mat-error>Client Name is required!</mat-error>
                </mat-form-field>


                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Product Type</mat-label>
                    <mat-select formControlName="productType" required>
                        <mat-option *ngFor="let product of productType" [value]="product.value">
                            {{product.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>
    <mat-step [stepControl]="formStep2" >

        <form fxLayout="column" [formGroup]="formStep2">

            <ng-template matStepLabel>Select Document Columns</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">

                <mat-form-field appearance="outline" fxFlex="100" *ngIf="esgGC">
                    <mat-label>GC ESG</mat-label>
                    <mat-select formControlName="gcEsg" multiple >
                        <mat-option *ngFor="let ec of gcEsg" [value]="ec.value">
                            {{ec.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                    <mat-error>GC ESG Columns are required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100"  *ngIf="prefFilter" >
                    <mat-label>Preferences</mat-label>
                    <mat-select formControlName="preferences" multiple>
                        <mat-option *ngFor="let pref of preferences" [value]="pref.value">
                            {{pref.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100" *ngIf="temperature">
                    <mat-label>Temperature / Emissions</mat-label>
                    <mat-select formControlName="temperatureE" multiple>
                        <mat-option *ngFor="let temp of temperatureE" [value]="temp.value">
                            {{temp.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100" *ngIf="catholic" >
                    <mat-label>Catholic Filter</mat-label>
                    <mat-select formControlName="catholicPreferences" multiple required>
                        <mat-option *ngFor="let cath of catholicPreferences" [value]="cath.value">
                            {{cath.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100" *ngIf="features">
                    <mat-label>Features</mat-label>
                    <mat-select formControlName="features" multiple>
                        <mat-option *ngFor="let feature of feat" [value]="feature.value">
                            {{feature.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Identifiers</mat-label>
                    <mat-select formControlName="identifiers" multiple >
                        <mat-option *ngFor="let detail of identifiers" [value]="detail.value">
                            {{detail.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                    <mat-error>Identifiers are required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Supplemental Information</mat-label>
                    <mat-select formControlName="supplementalInfo" multiple >
                        <mat-option *ngFor="let supplement of supplementalInfo" [value]="supplement.value">
                            {{supplement.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-icon matSuffix class="secondary-text">outlined_flag</mat-icon>
                    <mat-error>Supplemental Information are required!</mat-error>
                </mat-form-field>

              

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                    Previous
                </button>
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>

    <mat-step [stepControl]="formStep3">

        <form fxLayout="column" [formGroup]="formStep3">

            <ng-template matStepLabel>Document Delivery</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>File Name</mat-label>
                    <input matInput formControlName="fileName" >
                    <mat-error> File Name is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>File Type</mat-label>
                    <mat-select formControlName="fileType" required >
                        <mat-option *ngFor="let file of fileTypes" [value]="file.value">
                            {{file.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-error>File Type is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Split by row count</mat-label>
                    <input matInput formControlName="splitByRowCount">
                    <mat-error> Row count is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Separator</mat-label>
                    <input matInput formControlName="separator">
                    <mat-error> Separator is required!</mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Quoting</mat-label>
                    <input matInput formControlName="quoting">
                    <mat-error> Quoting is required!</mat-error>
                </mat-form-field>
                
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Null representation</mat-label>
                    <input matInput formControlName="nullRepresentation">
                    <mat-error> Null representation is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Portfolio</mat-label>
                    <input matInput formControlName="sray_version">
                    <mat-error> Portfolio is required!</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                    Previous
                </button>
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>
    <mat-step [stepControl]="formStep4">

        <form fxLayout="column" [formGroup]="formStep4">

            <ng-template matStepLabel>Document Delivery</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">
                <mat-form-field appearance="outline"  fxFlex="100">
                    <mat-label>Data Start Date</mat-label>
                    <input matInput [matDatepicker]="dataStartDate" name="dataStartDate" formControlName="dataStartDate" required>
                    <mat-datepicker-toggle matSuffix [for]="dataStartDate" ></mat-datepicker-toggle>
                    <mat-datepicker  #dataStartDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline"  fxFlex="100">
                    <mat-label>Data End Date</mat-label>
                    <input matInput [matDatepicker]="dataEndDate" name="dataEndDate" formControlName="dataEndDate" required>
                    <mat-datepicker-toggle matSuffix [for]="dataEndDate" ></mat-datepicker-toggle>
                    <mat-datepicker  #dataEndDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Data Frequency</mat-label>
                    <mat-select formControlName="dataFrequency" >
                        <mat-option *ngFor="let data of dataFrequency" [value]="data.value" required>
                            {{data.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Data History </mat-label>
                    <input matInput formControlName="dataHistory" >
                    <mat-error> Data History is required!</mat-error>
                </mat-form-field>
           
                    <div class="pl-4 mb-8" fxFlex="100">
                        <mat-slide-toggle [labelPosition]="'before'" formControlName="compress">Compress</mat-slide-toggle>
                    </div>
                   
                <mat-form-field appearance="outline"  fxFlex="100">
                    <mat-label>Delivery Start Date</mat-label>
                    <input matInput [matDatepicker]="deliveryStartDate" name="deliveryStartDate" formControlName="deliveryStartDate" required>
                    <mat-datepicker-toggle matSuffix [for]="deliveryStartDate" ></mat-datepicker-toggle>
                    <mat-datepicker  #deliveryStartDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline"  fxFlex="100">
                    <mat-label>Delivery End Date</mat-label>
                    <input matInput [matDatepicker]="deliveryEndDate" name="deliveryEndDate" formControlName="deliveryEndDate" required>
                    <mat-datepicker-toggle matSuffix [for]="deliveryEndDate"></mat-datepicker-toggle>
                    <mat-datepicker  #deliveryEndDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Delivery Frequency</mat-label>
                    <mat-select formControlName="deliveryFrequency" required>
                        <mat-option *ngFor="let del of deliveryFrequency" [value]="del.value">
                            {{del.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>

           

            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                    Previous
                </button>
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>

    <mat-step>

        <ng-template matStepLabel>Done</ng-template>

        <div class="h2 m-16" fxLayout="row" fxLayoutAlign="center center">
            Submit form to create a new Document
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                Previous
            </button>
            <button mat-raised-button type="button" color="accent"  mat-dialog-close (click)="finishHorizontalStepper()">
                Finish
            </button>
        </div>

    </mat-step>

</mat-horizontal-stepper>
<!-- / HORIZONTAL STEPPER EXAMPLE -->