 /* eslint-disable */
export interface DataField {
    field: {
        field_type?: string;
        app_name?: string;
        app_version?: number[];
        name: string;
    };
    formatter?: string;
}
export const allAddtionalDataFields = {
    date: {
       field: {
        field_type: 'primary_key',
        name: 'date'
       } 
    },
    isin: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'isin'
        }
    } as DataField,
    fspermid: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'fspermid'
        }
    } as DataField,
    fsymid: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'fsymid'
        }
    } as DataField,
    sedol: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'sedol'
        }
    } as DataField,
    ticker: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'ticker'
        }
    } as DataField,
    ticker_exchange: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'ticker_exchange'
        }
    } as DataField,
    ticker_region: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'ticker_region'
        }
    } as DataField,
    bbg_ticker: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'bbg_ticker'
        }
    } as DataField,
    cusip: {
        field: {
            app_name: 'assetinfo_security_id',
            app_version: [1, 0, 0, 0],
            name: 'cusip'
        }
    } as DataField,
    fsentityid: {
        field: {
            app_name: 'assetinfo_entity',
            app_version: [1, 0, 0, 0],
            name: 'fsentityid'
        }
    } as DataField,
    primary_asset_id: {
        field: {
            app_name: 'assetinfo_entity',
            app_version: [1, 0, 0, 0],
            name: 'primary_assetid'
        }
    } as DataField,
    name: {
        field: {
            app_name: 'assetinfo_name',
            app_version: [1, 0, 0, 0],
            name: 'name'
        }
    } as DataField,
    economic_sector: {
        field: {
            app_name: 'assetinfo_activity',
            app_version: [1, 0, 0, 0],
            name: 'economic_sector'
        }
    } as DataField,
    industry: {
        field: {
            app_name: 'assetinfo_activity',
            app_version: [1, 0, 0, 0],
            name: 'industry'
        }
    } as DataField,
    dom_region: {
        field: {
            app_name: 'assetinfo_dom_country',
            app_version: [1, 0, 0, 0],
            name: 'region'
        }
    } as DataField,
    dom_country_iso: {
        field: {
            app_name: 'assetinfo_dom_country',
            app_version: [1, 0, 0, 0],
            name: 'iso'
        }
    } as DataField,
    dom_country_iso2: {
        field: {
            app_name: 'assetinfo_dom_country',
            app_version: [1, 0, 0, 0],
            name: 'iso2'
        }
    } as DataField,
    dom_country_name: {
        field: {
            app_name: 'assetinfo_dom_country',
            app_version: [1, 0, 0, 0],
            name: 'dom_country_name'
        }
    } as DataField,
    exch_region: {
        field: {
            app_name: 'assetinfo_exch_country',
            app_version: [1, 0, 0, 0],
            name: 'region'
        }
    } as DataField,
    exch_country_iso: {
        field: {
            app_name: 'assetinfo_exch_country',
            app_version: [1, 0, 0, 0],
            name: 'exch_country_iso'
        }
    } as DataField,
    exch_country_iso2: {
        field: {
            app_name: 'assetinfo_exch_country',
            app_version: [1, 0, 0, 0],
            name: 'exch_country_iso2'
        }
    } as DataField,
    exch_country_name: {
        field: {
            app_name: 'assetinfo_exch_country',
            app_version: [1, 0, 0, 0],
            name: 'exch_country_name'
        }
    } as DataField,
    exchange_iso: {
        field: {
            app_name: 'assetinfo_exchange',
            app_version: [1, 0, 0, 0],
            name: 'exchange_iso'
        }
    } as DataField,
    exchange_mic: {
        field: {
            app_name: 'assetinfo_exchange',
            app_version: [1, 0, 0, 0],
            name: 'exchange_mic'
        }
    } as DataField,
    
    

};

















