import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { arabesqueAnimations } from '@kav/animations';
import { FilterDataService } from 'app/services/filter-data.service';
import { DeliveriesService } from '../enterprise/deliveries/deliveries.service';
import { ModalComponent } from '../enterprise/modal/modal.component';
import { DispartchPortfolioModalComponent } from './dispatch-portfolio/dispartch-portfolio-modal/dispartch-portfolio-modal.component';
import { PortfolioModalComponent } from './portfolio-modal/portfolio-modal.component';
import { PortfolioWizardComponent } from './portfolio-wizard/portfolio-wizard.component';
import { PortfoliosService } from './portfolios.service';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.scss'],
  animations: arabesqueAnimations,
  encapsulation: ViewEncapsulation.None
})
export class PortfolioComponent implements OnInit, AfterViewInit {
@ViewChild('filter', {static: true}) filter: ElementRef;
dispatchPortfoliosTab = false;
portfoliosTab = true;
portfolioDeliveriesTab = false;
tableData: any[];
displayedColumns: string[] = ['id', 'meta.client_name', 'name', 'date_created', 'date_modified'];
route = '/portfolio/document';
active: boolean;

  constructor(public dialog: MatDialog,
              private portfolioDataService: PortfoliosService, 
              private filterService: FilterDataService ) { }

  ngOnInit(): void {
    this.tableData = this.portfolioDataService.portfolioDocs;
  }
  createNewDocuments(): void {
   this.dialog.open(PortfolioModalComponent);
     }
     dispatchNewDocuments(): void {
         this.dialog.open(DispartchPortfolioModalComponent, {
           height: '1000px',
           width: '800px',
         });
     }
     onLinkClick(event): void {
        if (event.index === 0) {
            this.filter.nativeElement.value = '';
            this.filterService.changeTableData(this.filter.nativeElement.value);
            this.dispatchPortfoliosTab = false;
            this.portfoliosTab = true;
            this.portfolioDeliveriesTab = false;
        }
        if (event.index === 1) {
            this.filter.nativeElement.value = '';
            this.filterService.changeTableData(this.filter.nativeElement.value);
            this.dispatchPortfoliosTab = true;
            this.portfoliosTab = false;
            this.portfolioDeliveriesTab = false;
        }
        if (event.index === 2) {
            this.filter.nativeElement.value = '';
            this.filterService.changeTableData(this.filter.nativeElement.value);
            this.dispatchPortfoliosTab = false;
            this.portfoliosTab = false;
            this.portfolioDeliveriesTab = true;
            this.active = true;
          }
    }
    ngAfterViewInit(): void {
    }
    applyFilter(event: Event): void {
        this.filterService.changeTableData(this.filter.nativeElement.value);
      }

}
