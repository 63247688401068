import { Injectable } from '@angular/core';
import { debounce } from 'lodash';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable()
export class FilterDataService {
    public selectedFilterValue: Subject<string> = new Subject<string>();

    changeTableData(filterValue: string): void {
    const value =  filterValue.trim().toLowerCase();
    this.selectedFilterValue.next(value);
    }
}
