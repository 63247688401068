 /* eslint-disable */
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { arabesqueAnimations } from '@kav/animations';
import { FilterDataService } from 'app/services/filter-data.service';
import {DocumentsService} from '../../../services/documents.service';

  


@Component({
  selector: 'app-e-table',
  templateUrl: './e-table.component.html',
  styleUrls: ['./e-table.component.scss'],
  animations: arabesqueAnimations
})
export class ETableComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @Input() displayedColumns: string[];
    @Input() tableData: any[];
    @Input() route: string;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('filter', {static: true}) filter: ElementRef;
    clickCount = 0;
   
    dataSource: any;
  

  constructor(private router: Router, private filterService: FilterDataService) {
   }

  ngOnInit(): void {
    this.dataSource  = new MatTableDataSource(this.tableData);
    this.dataSource.filterPredicate = (data, filter: string): boolean => data.id.toLowerCase().includes(filter) || data.meta.client_name.toLowerCase().includes(filter)
        || data.name.toLowerCase().includes(filter)
         || data.date_created.includes(filter) || data.date_modified.includes(filter);
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }
  test(event, row): void {
    this.clickCount++;
  
    const id = row['id'];
    setTimeout(() => {
        if (this.clickCount === 1) {
             // single
        } else if (this.clickCount === 2) {
            // double
            this.router.navigateByUrl(`${this.route}/${id}`);
        }
        this.clickCount = 0;
    }, 250);

  }
  ngAfterViewChecked(): void {
       this.filterService.selectedFilterValue.subscribe(data => this.dataSource.filter = data);
  }
 
 
}


