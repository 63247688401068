 /* eslint-disable */
import { Component, Host, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, ControlContainer } from '@angular/forms';
import { NewDocRegDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-primary-info',
  templateUrl: './primary-info.component.html',
  styleUrls: ['./primary-info.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class PrimaryInfoComponent implements OnInit {
    @Input() document: NewDocRegDocument;
    primaryInfo: any;
    form: FormGroup;
    fgd: FormGroupDirective;
  
  constructor( private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective, parent: FormGroupDirective) {
      this.fgd = parent;
   }

  ngOnInit(): void {
    this.form = this.parentFor.form;
    console.log(`edit doc`);
    console.log(this.document);
       // Reactive Form
    this.form.addControl('primaryInfo', this.formBuilder.group(
        {
        
            name: [this.document.name, Validators.required],
            description: [this.document.description, Validators.required],
            clientName: [this.document.meta.client_name, Validators.required],
        }
    ));

  }

}
