 /* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import {authToken, DocRegDocument, Document, DocumentData, NewDocRegDocument, PortfolioDocument} from 'app/fake-db/real-doc';
import { DocumentsService } from 'app/services/documents.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeliveriesService } from '../deliveries/deliveries.service';

@Injectable({
  providedIn: 'root'
})
export class DocumentService implements Resolve<any> {
    routeParams: any;
    document: NewDocRegDocument | DocRegDocument;
    onDocumentChanged: BehaviorSubject<any>;
    authToken: string = sessionStorage.getItem('token');
    constructor( private router: Router, private active: ActivatedRoute, private docs: DocumentsService, private http: HttpClient, private deliveries: DeliveriesService) {
    this.onDocumentChanged = new BehaviorSubject({});
   }
 
   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getDocument(route.params.id),
                this.deliveries.getDocBuild(route.params.id)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getDocument(id: string): Promise<any> {
        const authHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        });

        return new Promise((resolve, reject) => {
            this.http.get(`http://127.0.0.1:8080/doc-registry/api/v1/document/${id}`,
            {headers: authHeaders})
                .subscribe((response: any) => {
                    this.document = response;
                    this.onDocumentChanged.next(this.document);
                    resolve(response);
                }, reject);
        });
    }

}
