 /* eslint-disable */
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { merge, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { KavigationService } from '@kav/components/navigation/navigation.service';

@Component({
    selector       : 'kavigation',
    templateUrl    : './navigation.component.html',
    styleUrls      : ['./navigation.component.scss'],
    encapsulation  : ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class KavigationComponent implements OnInit
{
    @Input()
    layout = 'horizontal';

    @Input()
    navigation: any;

    // Private
    private unsubscribeAll: Subject<any>;

    constructor(
        private changeDetectorRef: ChangeDetectorRef,
        private kavigationService: KavigationService
    )
    {
        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Load the navigation either from the input or from the service
        this.navigation = this.navigation || this.kavigationService.getCurrentNavigation();

        // Subscribe to the current navigation changes
        this.kavigationService.onNavigationChanged
            .pipe(takeUntil(this.unsubscribeAll))
            .subscribe(() => {

                // Load the navigation
               this.navigation = this.kavigationService.getCurrentNavigation();

                // Mark for check
               this.changeDetectorRef.markForCheck();
            });

        // Subscribe to navigation item
        merge(
            this.kavigationService.onNavigationItemAdded,
            this.kavigationService.onNavigationItemUpdated,
            this.kavigationService.onNavigationItemRemoved
        ).pipe(takeUntil(this.unsubscribeAll))
         .subscribe(() => {

             // Mark for check
             this.changeDetectorRef.markForCheck();
         });
    }
}
