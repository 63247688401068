 <!-- REACTIVE FORM EXAMPLE -->
 <form formGroupName="portfolioInfo" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
 fxFlex="1 0 auto">
 <div class="h2 mb-24">Reactive Form Example</div>

 <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
     
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>Portfolio Name</mat-label>
        <input matInput formControlName="portfolio" >
        <mat-error>Portfolio  name is required!</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>Portfolio Label</mat-label>
        <input matInput formControlName="portfolioLabel" >
        <mat-error>Portfolio label is required!</mat-error>
    </mat-form-field>
 </div>
 
 <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
     
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>Benchmark</mat-label>
        <input matInput formControlName="benchmark" >
        <mat-error>Benchmark is required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>DB API</mat-label>
        <mat-select formControlName="dbApi" >
            <mat-option value="yes">
               Yes
              </mat-option>
              <mat-option value="no">
                No
               </mat-option>
             
        </mat-select>
    </mat-form-field>
 </div>
 
 <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
     
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>File Name</mat-label>
        <input matInput formControlName="fileName" >
        <mat-error>File  name is required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>Id Field</mat-label>
        <input matInput formControlName="idField" >
        <mat-error>ID Field is required!</mat-error>
    </mat-form-field>
 </div>
 
 <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
     
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>SRay Version</mat-label>
        <input matInput formControlName="srayVersion" >
        <mat-error>SRay Version is required!</mat-error>
    </mat-form-field>
 </div>

 
 
 </form>
