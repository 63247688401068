<div fxLayout="row wrap" fxLayoutGap="32px" fxLayoutAlign="flex-start" >
    <div class="mb-8" *ngFor="let build of docBuilds">
            <div class="fuse-card" fxFlex="0 1 calc(33.3% - 32px)" >

                <div class="p-16">
                    <div class="h2">{{build.id}}</div>
                    <div class="h4 secondary-text">Date Modified {{build.date_modified | date}}</div>

            
              
                <p>Active: {{build.active}}</p>
                <p>Date Created: {{build.date_created | date}}</p>
                <p>Path: <span class="build-path">{{build.path | url}}</span></p>
                </div>
               
              
            
            </div>
     
     
    
    </div>
 

</div>
 


