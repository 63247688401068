/* eslint-disable */
import {DataField} from './data-fields';
export const allGcEsgDataFields = {
    gc: {
        field: {
            app_name: 'GC',
            app_version: [2, 6, 2, 0],
            name: 'gc'
        }
    } as DataField,
    gc_hr: {
        field: {
            app_name: 'GC',
            app_version: [2, 6, 2, 0],
            name: 'gc_hr'
        }
    } as DataField,
    gc_lr: {
        field: {
            app_name: 'GC',
            app_version: [2, 6, 2, 0],
            name: 'gc_lr'
        }
    } as DataField,
    gc_en: {
        field: {
            app_name: 'GC',
            app_version: [2, 6, 2, 0],
            name: 'gc_en'
        }
    } as DataField,
    gc_ac: {
        field: {
            app_name: 'GC',
            app_version: [2, 6, 2, 0],
            name: 'gc_ac'
        }
    } as DataField,
    esg: {
        field: {
            app_name: 'ESG',
            app_version: [2, 6, 2, 0],
            name: 'esg'
        }
    } as DataField,
    esg_e: {
        field: {
            app_name: 'ESG',
            app_version: [2, 6, 2, 0],
            name: 'esg_e'
        }
    } as DataField,
    esg_s: {
        field: {
            app_name: 'ESG',
            app_version: [2, 6, 2, 0],
            name: 'esg_s'
        }
    } as DataField,
    esg_g: {
        field: {
            app_name: 'ESG',
            app_version: [2, 6, 2, 0],
            name: 'esg_g'
        }
    } as DataField
};
