<form fxLayout="column" [formGroup]="dispatchForm">

    <div fxFlex="1 0 auto" fxLayout="column">

        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Dispatch Job Name</mat-label>
            <input matInput formControlName="name">
            <mat-error>Name is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Comment</mat-label>
            <input matInput formControlName="comment" >
            <mat-error>Comment is required</mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Delivery Type</mat-label>
            <mat-select formControlName="deliveryJobType" required>
                <mat-option *ngFor="let delivery of deliveryJobTypes" [value]="delivery.value">
                    {{delivery.viewValue}}
                  </mat-option>
            </mat-select>
        </mat-form-field>
        <ng-container *ngIf="emailDeliveryType">
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Email From</mat-label>
                <input type="email" matInput formControlName="emailFrom" >
                <mat-error>Email from is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Email To</mat-label>
                <input type="email" matInput formControlName="emailTo" >
                <mat-error>Email to is required</mat-error>
            </mat-form-field>
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Email Subject</mat-label>
                <input  matInput formControlName="emailSubject" >
                <mat-error>Email is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>Email Body</mat-label>
                <input  matInput formControlName="emailBody" >
                <mat-error>Email body is required</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="s3DeliveryType">
            
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>AWS Access Key Id</mat-label>
                <input  matInput formControlName="awsAccessKeyId" >
                <mat-error>Aws access key is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>AWS Secret Access Key Id</mat-label>
                <input  matInput formControlName="awsSecretAccessKey" >
                <mat-error>aws secret is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>AWS Region</mat-label>
                <input  matInput formControlName="awsRegion" >
                <mat-error>Aws region is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>AWS Bucket</mat-label>
                <input  matInput formControlName="awsBucket" >
                <mat-error>Aws bucket is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>AWS Path</mat-label>
                <input  matInput formControlName="awsPath" >
                <mat-error>Aws path is required</mat-error>
            </mat-form-field>
        </ng-container>

        <ng-container *ngIf="ftpDeliveryType">
            
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>FTP Username</mat-label>
                <input  matInput formControlName="ftpUsername" >
                <mat-error>FTP username key is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>FTP Password</mat-label>
                <input  matInput formControlName="ftpPassword" >
                <mat-error>FTP password is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>FTP Host</mat-label>
                <input  matInput formControlName="ftpHost" >
                <mat-error>FTP host is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>FTP Port</mat-label>
                <input  matInput formControlName="ftpPort" >
                <mat-error>FTP port is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>FTP Path</mat-label>
                <input  matInput formControlName="ftpPath" >
                <mat-error>FTP path is required</mat-error>
            </mat-form-field>

        </ng-container>

        <ng-container *ngIf="sftpDeliveryType">
            
            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>SFTP Username</mat-label>
                <input  matInput formControlName="sftpUsername" >
                <mat-error>SFTP username key is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>SFTP Password</mat-label>
                <input  matInput formControlName="sftpPassword" >
                <mat-error>SFTP password is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>SFTP Host</mat-label>
                <input  matInput formControlName="sftpHost" >
                <mat-error>SFTP host is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>SFTP Port</mat-label>
                <input  matInput formControlName="sftpPort" >
                <mat-error>SFTP port is required</mat-error>
            </mat-form-field>

            <mat-form-field appearance="outline" fxFlex="100">
                <mat-label>SFTP Path</mat-label>
                <input  matInput formControlName="sftpPath" >
                <mat-error>SFTP path is required</mat-error>
            </mat-form-field>

        </ng-container>

        <mat-form-field appearance="outline" fxFlex="100">
            <mat-chip-list #chipList aria-label="Document selection">
                <mat-chip color="sray" *ngFor="let doc of selectedDocuments" [selectable]="selectable"
                         [removable]="removable" (removed)="remove(doc)">
                  {{doc.name}}
                  <mat-icon matChipRemove *ngIf="removable">cancel</mat-icon>
                </mat-chip>
                <input placeholder="Documents to be built"
                       [matChipInputFor]="chipList"
                       [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                       [matChipInputAddOnBlur]="addOnBlur"
                       >
              </mat-chip-list>
        </mat-form-field>

       

    </div>


</form>