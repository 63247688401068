import { Injectable } from '@angular/core';
import { MatSnackBarConfig, MatSnackBarRef, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MatSnackBar } from '@angular/material/snack-bar';


@Injectable({
  providedIn: 'root'
})
export class SnackbarService {

 
    snackBarConfig: MatSnackBarConfig;
    snackBarRef: MatSnackBarRef<any>;
    horizontalPosition: MatSnackBarHorizontalPosition = 'center';
    verticalPosition: MatSnackBarVerticalPosition = 'top';
    snackBarAutoHide = '1500';
  
    constructor(private snackBar: MatSnackBar) { }
  
    openSnackBar(message) {
      this.snackBarConfig = new MatSnackBarConfig();
      this.snackBarConfig.horizontalPosition = this.horizontalPosition;
      this.snackBarConfig.verticalPosition = this.verticalPosition;
      // eslint-disable-next-line radix
      this.snackBarConfig.duration = parseInt(this.snackBarAutoHide, 0);
      this.snackBarConfig.panelClass = 'custom-snackbar';
      this.snackBarRef = this.snackBar.open(message, '', this.snackBarConfig);
  }
}
