/* eslint-disable */
import {DataField} from './data-fields';
export const allTemperatureDataFields = {
    temp_score_near: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'score_near'
        }
    } as DataField,
    temp_score_far: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'score_far'
        }
    } as DataField,
    temp_trend: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'trend'
        }
    } as DataField,
    temp_target: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'target'
        }
    } as DataField,
    temp_scope3: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'scope3'
        }
    } as DataField,
    temp_iea_sector: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'iea_sector'
        }
    } as DataField,
    temp_eir: {
        field: {
            app_name: 'temperature',
            app_version: [1, 1, 0, 0],
            name: 'eir'
        }
    } as DataField,
    temp_em_1: {
        field: {
            app_name: 'temperature_emissions',
            app_version: [1, 1, 0, 0],
            name: 'em_1'
        }
    } as DataField,
    temp_em_2: {
        field: {
            app_name: 'temperature_emissions',
            app_version: [1, 1, 0, 0],
            name: 'em_2'
        }
    } as DataField,
    temp_em_3: {
        field: {
            app_name: 'temperature_emissions',
            app_version: [1, 1, 0, 0],
            name: 'em_3'
        }
    } as DataField,
    temp_em_date: {
        field: {
            app_name: 'temperature_emissions',
            app_version: [1, 1, 0, 0],
            name: 'em_date'
        }
    } as DataField,
    temp_va_usd: {
        field: {
            app_name: 'temperature_financials',
            app_version: [1, 1, 0, 0],
            name: 'va_usd'
        }
    } as DataField,
    temp_ppp_adj: {
        field: {
            app_name: 'temperature_financials',
            app_version: [1, 1, 0, 0],
            name: 'ppp_adjustment'
        }
    } as DataField,
    temp_cpi_adjustment: {
        field: {
            app_name: 'temperature_financials',
            app_version: [1, 1, 0, 0],
            name: 'cpi_adjustment'
        }
    } as DataField,
    temp_revenue: {
        field: {
            app_name: 'temperature_financials',
            app_version: [1, 1, 0, 0],
            name: 'revenue'
        }
    } as DataField,
    temp_sbti: {
        field: {
            app_name: 'temperature_sbti',
            app_version: [1, 1, 0, 0],
            name: 'sbti_value'
        }
    } as DataField
};
