 /* eslint-disable */
import { AfterViewInit, Component, Host, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, ControlContainer } from '@angular/forms';
import { DataField } from 'app/fake-db/data-fields';
import { DocumentColumns } from 'app/fake-db/form-helpers';
import { NewDocRegDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-product-info',
  templateUrl: './product-info.component.html',
  styleUrls: ['./product-info.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class ProductInfoComponent implements OnInit, AfterViewInit {
    @Input() document: NewDocRegDocument;
    @Input() esgGC: boolean;
    @Input() temperature: boolean;
    @Input() prefFilter: boolean;
    @Input() features: boolean;
    @Input() catholic: boolean;
    @Input() gcESG: DataField[];
    @Input() catholicPreferences: DataField[];
    @Input() identifiers: DataField[];
    @Input() preferences: DataField[];
    @Input() supplementalInfo: DataField[];
    @Input() temperatureE: DataField[];
    @Input() feat: string[];
    @Input() allIdentifiers: DocumentColumns[]; 
    @Input() allGcEsg: DocumentColumns[]; 
    @Input() allPreferences: DocumentColumns[]; 
    @Input() allDataFrequency: DocumentColumns[];
    @Input() allDeliveryFrequency: DocumentColumns[]; 
    @Input() allProductType: DocumentColumns[]; 
    @Input() allSupplementalInfo: DocumentColumns[]; 
    @Input() allTemperatureE: DocumentColumns[]; 
    @Input() allCatholicPreferences: DocumentColumns[];
    @Input() allFeatures: DocumentColumns[];
    productInfo: any;
    form: FormGroup;
   
    
  constructor( private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective) { }

  ngOnInit(): void {
      this.form = this.parentFor.form;
    
      this.form.addControl('productInfo', this.formBuilder.group(
        {
            identifiers:  [this.identifiers],
            gcEsg: [this.gcESG],
            temperatureE: [this.temperatureE],
            preferences: [this.preferences],
            catholicPreferences: [this.catholicPreferences],
            supplementalInfo: [this.supplementalInfo],
            features: [this.feat]
        }
      ));
      // eslint-disable-next-line @typescript-eslint/prefer-for-of
      
    
      
      
      

  }
  ngAfterViewInit(): void {
     // Reactive Form
    
    
  }

}
