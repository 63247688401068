 /* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { authToken, NewDocRegDocument, PortfolioDocument } from 'app/fake-db/real-doc';
import { BehaviorSubject, Observable } from 'rxjs';
import { DeliveriesService } from '../enterprise/deliveries/deliveries.service';

@Injectable({
  providedIn: 'root'
})
export class PortfoliosService {

    routeParams: any;
    public documents: NewDocRegDocument[] = undefined;
    public portfolioDocs: PortfolioDocument[] = undefined;
    onDocumentChanged: BehaviorSubject<any>;
     authToken: string = sessionStorage.getItem('token');
  constructor(private http: HttpClient, private deliveries: DeliveriesService) {
    this.onDocumentChanged = new BehaviorSubject({});
   }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        console.log('in resolve');
        return new Promise<void>((resolve, reject) => {

            Promise.all([
                this.getDocuments(),
                this.deliveries.getDocBuild(route.params.id)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getDocuments(): Promise<any> {
        const authHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        });

        return new Promise((resolve, reject) => {
            this.http.get('http://127.0.0.1:8080/doc-registry/api/v1/document/',
            {headers: authHeaders})
                .subscribe((response: any) => {
                    this.portfolioDocs = this.onlyNewPortfolios(response);
                    this.onDocumentChanged.next(this.documents);
                    resolve(response);
                }, reject);
        });
    }
    private onlyPortfolioDocs(documents: NewDocRegDocument[]): PortfolioDocument[] {
        const Enterprisedocs = [];
        documents.map(a => {
            if (a.meta.dev_sray_service === 'ptfscreen'){
                Enterprisedocs.push(a);
            }
        });
        return Enterprisedocs;
    }
    private onlyNewPortfolios(documents: any []): PortfolioDocument[] {
        const portfolioDocs = [];
        documents.map(a => {
            if (a.meta.new_pf === true) {
                portfolioDocs.push(a);
            }
        });
        return portfolioDocs;
    }

}
