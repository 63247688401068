import { ENTER, COMMA } from '@angular/cdk/keycodes';
import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { deliveryJobTypes } from 'app/fake-db/form-helpers';
import { NewDocRegDocument } from 'app/fake-db/real-doc';
import { DispatcherTableService } from 'app/main/shared//dispatch/dispatcher-table/dispatcher-table.service';

@Component({
  selector: 'app-dispatch-portfolio-wizard',
  templateUrl: './dispatch-portfolio-wizard.component.html',
  styleUrls: ['./dispatch-portfolio-wizard.component.scss']
})
export class DispatchPortfolioWizardComponent implements OnInit, AfterViewChecked {

    selectedDocuments: NewDocRegDocument[];
    visible = true;
    selectable = true;
    removable = true;
    addOnBlur = true;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    dispatchForm: FormGroup;
    deliveryJobTypes = deliveryJobTypes;
    emailDeliveryType = false;
    s3DeliveryType = false;
    ftpDeliveryType = false;
    sftpDeliveryType = false;
      constructor(private dispatch: DispatcherTableService, private formBuilder: FormBuilder,
                  private cd: ChangeDetectorRef) { }
    
      ngOnInit(): void {
         this.dispatch.currentPortfolioSelectedDocuments.subscribe(data => this.selectedDocuments = data);
         this.dispatchForm = this.formBuilder.group({
             comment: [''],
             deliveryJobType: [''],
             name: [''],
             awsAccessKeyId: [''],
             awsSecretAccessKey: [''],
             awsRegion: [''],
             awsBucket: [''],
             awsPath: [''],
             emailFrom: ['', Validators.email],
             emailTo: ['', Validators.email],
             emailSubject: [''],
             emailBody: [''],
             ftpUsername: [''],
             ftpPassword: [''],
             ftpHost: [''],
             ftpPort: [''],
             ftpPath: [''],
             sftpUsername: [''],
             sftpPassword: [''],
             sftpHost: [''],
             sftpPort: [''],
             sftpPath: ['']
         });
      }
    
    
      remove(doc: NewDocRegDocument): void {
        const index = this.selectedDocuments.indexOf(doc);
    
        if (index >= 0) {
          this.selectedDocuments.splice(index, 1);
        }
      }
      ngAfterViewChecked(): void {
          switch (this.dispatchForm.value.deliveryJobType) {
            case 'DeliveryEmailJob':
              this.emailDeliveryType = true;
              this.s3DeliveryType = false;
              this.ftpDeliveryType = false;
              this.sftpDeliveryType = false;
              this.cd.detectChanges();
              break;
            case 'DeliveryAWSS3Job':
              this.s3DeliveryType = true;
              this.emailDeliveryType = false;
              this.ftpDeliveryType = false;
              this.sftpDeliveryType = false;
              this.cd.detectChanges();
              break;
            case 'DeliveryFTPJob':
              this.ftpDeliveryType = true;
              this.emailDeliveryType = false;
              this.s3DeliveryType = false;
              this.sftpDeliveryType = false;
              this.cd.detectChanges();
              break;
            case 'DeliverySFTPJob':
              this.sftpDeliveryType = true;
              this.emailDeliveryType = false;
              this.s3DeliveryType = false;
              this.ftpDeliveryType = false;
              this.cd.detectChanges();
              break;
           
        }    
    }

}
