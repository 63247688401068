 /* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { authToken, PortfolioDocument } from 'app/fake-db/real-doc';
import { DocumentsService } from 'app/services/documents.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { testPortfolioDocument} from 'app/fake-db/sample-portfolio';
import { reject } from 'lodash';

@Injectable({
  providedIn: 'root'
})
export class PortfolioDocumentService {

    routeParams: any;
    document: PortfolioDocument;
    onDocumentChanged: BehaviorSubject<any>;
    authToken: string = sessionStorage.getItem('token');
    hardcoded: PortfolioDocument = testPortfolioDocument;
    constructor( private router: Router, private active: ActivatedRoute, private docs: DocumentsService, private http: HttpClient) {
    this.onDocumentChanged = new BehaviorSubject({});
   }
 
   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        // eslint-disable-next-line no-shadow
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDocument(route.params.id)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getDocument(id: string): Promise<any> {
        const authHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        });

        // eslint-disable-next-line no-shadow
        return new Promise((resolve, reject) => {
            this.http.get(`http://127.0.0.1:8080/doc-registry/api/v1/document/${id}`,
            {headers: authHeaders})
                .subscribe((response: any) => {
                    this.document = response;
                    this.onDocumentChanged.next(this.document);
                    resolve(response);
                }, reject);
        });
    }
    
    

}
