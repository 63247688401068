 /* eslint-disable */
import { SelectionModel } from '@angular/cdk/collections';
import { AfterContentChecked, AfterViewChecked, AfterViewInit, Component, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { arabesqueAnimations } from '@kav/animations';
import { NewDocRegDocument } from 'app/fake-db/real-doc';
import { DocumentsService } from 'app/services/documents.service';
import { FilterDataService } from 'app/services/filter-data.service';
import { DispatcherTableService } from './dispatcher-table.service';

@Component({
  selector: 'app-dispatcher-table',
  templateUrl: './dispatcher-table.component.html',
  styleUrls: ['./dispatcher-table.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: arabesqueAnimations
})
export class DispatcherTableComponent implements OnInit, AfterViewInit, AfterContentChecked, AfterViewChecked {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Input() tableData: any[];
    @Input() productType: string;
    @Input() route: string;
    displayedColumns: string[] = ['select', 'id', 'meta.client_name', 'name',  'date_modified', 'active', 'edit'];
    dataSource: any;
  
    selection = new SelectionModel<NewDocRegDocument>(true, []);
  constructor(private router: Router,
              private docs: DocumentsService,
              private dispatch: DispatcherTableService,
              private filterService: FilterDataService) {
   }

  ngOnInit(): void {
    this.dataSource  = new MatTableDataSource(this.tableData);
    this.dataSource.filterPredicate = (data, filter: string): boolean => data.id.toLowerCase().includes(filter) || data.meta.client_name.toLowerCase().includes(filter)
        || data.name.toLowerCase().includes(filter)
         || data.date_created.includes(filter) || data.date_modified.includes(filter);
        
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'meta.client_name': return item.meta.client_name;
          default: return item[property];
        }
      };
    this.dataSource.sort = this.sort;
   
   
  }
  isAllSelected(): any {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }
 
  

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle(): any {
      if (this.productType === 'enterprise') {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.dispatch.changeEnterpriseSelectedDocuments(null); 
        } else {
            this.dataSource.data.forEach(row => this.selection.select(row));
            this.dispatch.changeEnterpriseSelectedDocuments(this.selection.selected);
        } 
      } else {
        if (this.isAllSelected()) {
            this.selection.clear();
            this.dispatch.changePortfolioSelectedDocuments(null); 
        } else {
            this.dataSource.data.forEach(row => this.selection.select(row));
            this.dispatch.changePortfolioSelectedDocuments(this.selection.selected);
        } 
      }
    
       
       
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: NewDocRegDocument): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.id}`;
  }
  selectedDocuments(): void {
      if (this.productType === 'enterprise') {
        if (this.selection.isEmpty) {
            this.dispatch.changeEnterpriseSelectedDocuments(null);
        } else {
          this.dispatch.changeEnterpriseSelectedDocuments(this.selection.selected);
        }
      } else {
        if (this.selection.isEmpty) {
            this.dispatch.changePortfolioSelectedDocuments(null);
        } else {
          this.dispatch.changePortfolioSelectedDocuments(this.selection.selected);
        }
      }
      
     
  }
  ngAfterContentChecked(): void {
      if (this.productType === 'enterprise') {
        if (this.selection.hasValue()) {
            this.dispatch.changeEnterpriseSelectedDocuments(this.selection.selected);
        }
      } else {
        if (this.selection.hasValue()) {
            this.dispatch.changePortfolioSelectedDocuments(this.selection.selected);
        }
      }
    
  }
  ngAfterViewChecked(): void {
    this.filterService.selectedFilterValue.subscribe(data => this.dataSource.filter = data);
}
edit(event, element): void {
    const id = element['id'];
    this.router.navigateByUrl(`${this.route}/${id}`);
}

}
