import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Deliveries } from 'app/fake-db/deliveries';
import { DeliveryService } from './delivery.service';

@Component({
  selector: 'app-delivery',
  templateUrl: './delivery.component.html',
  styleUrls: ['./delivery.component.scss']
})
export class DeliveryComponent implements OnInit {
delivery: Deliveries;
form = new FormGroup({});
  constructor(private service: DeliveryService, private router: Router, private ref: ChangeDetectorRef) {
    this.delivery = this.service.delivery;
   }

  ngOnInit(): void {
  }
  saveDelivery(): void {
  }

}
