 <!-- REACTIVE FORM EXAMPLE -->

 <form formGroupName="portfolioDelivery" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
 fxFlex="1 0 auto">

 <div class="h2 mb-24">Reactive Form Example</div>

 <div fxFlex="1 0 auto" fxLayout="row">
 <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
    <mat-label>Temperature Version</mat-label>
    <input matInput formControlName="temperatureVersion" >
    <mat-error>Temperature Version is required!</mat-error>
</mat-form-field>

<mat-form-field appearance="outline" fxFlex="50" class="pl-4">
    <mat-label>Template</mat-label>
    <input matInput formControlName="template" >
    <mat-error>Template is required!</mat-error>
</mat-form-field>

 </div>
  
  
    <div fxFlex="1 0 auto" fxLayout="row">
        <div class="pl-4 mb-8" fxFlex="100">
            <mat-slide-toggle [labelPosition]="'before'" formControlName="withCatholic">Catholic</mat-slide-toggle>
            <mat-slide-toggle [labelPosition]="'before'" formControlName="withTemperature">Temperature</mat-slide-toggle>
            <mat-slide-toggle [labelPosition]="'before'" formControlName="withFeatures">Features</mat-slide-toggle>
        </div>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline"  fxFlex="100">
        <mat-label>Delivery Start Date</mat-label>
        <input matInput [matDatepicker]="deliveryStartDate" name="deliveryStartDate" formControlName="deliveryStartDate">
        <mat-datepicker-toggle matSuffix [for]="deliveryStartDate" ></mat-datepicker-toggle>
        <mat-datepicker  #deliveryStartDate></mat-datepicker>
    </mat-form-field>
    </div>

    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Delivery Frequency</mat-label>
        <mat-select formControlName="deliveryFrequency">
            <mat-option *ngFor="let del of allDeliveryFrequency" [value]="del.value">
                {{del.viewValue}}
              </mat-option>
        </mat-select>
    </mat-form-field>


</div>
 </form>




<!-- / REACTIVE FORM EXAMPLE -->