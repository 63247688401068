 /* eslint-disable */
import { Component, Host, Input, OnInit } from '@angular/core';
import { ControlContainer, FormGroupDirective, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DocumentColumns } from 'app/fake-db/form-helpers';
import { PortfolioDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-portfolio-document-delivery',
  templateUrl: './portfolio-document-delivery.component.html',
  styleUrls: ['./portfolio-document-delivery.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class PortfolioDocumentDeliveryComponent implements OnInit {
    @Input() portfolioDoc: PortfolioDocument;
    @Input() allDeliveryFrequency: DocumentColumns[];
    form: FormGroup;
    fgd: FormGroupDirective;
    portfolioDelivery: any;
   
    constructor(private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective) { }

    ngOnInit(): void {
      this.form = this.parentFor.form;
     
         // Reactive Form
      this.form.addControl('portfolioDelivery', this.formBuilder.group(
          {
          
            version_string: [this.portfolioDoc.meta.sray.app_versions],
            temperatureVersion: [this.portfolioDoc.meta.temperature_version],
            template: [this.portfolioDoc.meta.template],
            withCatholic: [this.portfolioDoc.meta.with_catholic],
            withFeatures: [this.portfolioDoc.meta.with_features],
            withTemperature: [this.portfolioDoc.meta.with_temperature],
            deliveryStartDate: [this.portfolioDoc.meta.delivery_start_date],
            deliveryFrequency:  [this.portfolioDoc.meta.delivery_frequency]   
          }
      ));
  
    }
}
