import { AfterContentChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { arabesqueAnimations } from '@kav/animations';
import { DocumentColumns, deliveryFrequency } from 'app/fake-db/form-helpers';
import { PortfolioDocument } from 'app/fake-db/real-doc';
import { DocumentService } from 'app/main/enterprise/document/document.service';
import { DeleteDocumentModalComponent } from 'app/main/shared/delete-document-modal/delete-document-modal.component';
import { SnackbarService } from 'app/main/shared/snackbar/snackbar.service';
import { EditDocumentService } from 'app/services/edit-document.service';
import { EditPortfolioDocumentService } from 'app/services/edit-portfolio-document.service';
import { PortfolioDocumentService } from './portfolio-document.service';

@Component({
  selector: 'app-portfolio-document',
  templateUrl: './portfolio-document.component.html',
  styleUrls: ['./portfolio-document.component.scss'],
  animations: arabesqueAnimations
})
export class PortfolioDocumentComponent implements OnInit, AfterContentChecked {
    portfolioDoc: PortfolioDocument;
    columns: string[];
    pForm = new FormGroup({});
    allDeliveryFrequency: DocumentColumns[] = deliveryFrequency;
      constructor(private service: PortfolioDocumentService,
                  private router: Router,
                  private ref: ChangeDetectorRef,
                  private editService: EditPortfolioDocumentService,
                  private snackbar: SnackbarService, 
                  public dialog: MatDialog) {
          this.portfolioDoc = this.service.document;
       }
    
    
      ngOnInit(): void {
        
         
    
      }
      ngAfterContentChecked(): void {
        this.ref.detectChanges();
      }
      saveDocument(): void {
        this.editService.editPortfolioDocument(this.portfolioDoc, this.pForm.value).subscribe(data => {
            this.snackbar.openSnackBar(`Edited ${this.portfolioDoc.id} successfully`);
            this.router.navigate(['portfolio']);
        });
      }
      deleteDocumentModal(): void {
        this.dialog.open(DeleteDocumentModalComponent, {
             data: this.portfolioDoc
           });
     }

}
