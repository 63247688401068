import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'url'
})
export class UrlPipe implements PipeTransform {

  transform(uri: string): string {
   const realUri = uri;
   const stringArray = realUri.split('/');
   const expression = /\s+/g;
   if (stringArray[5] === 'Arabesque S-Ray') {
    stringArray[5] = stringArray[5].replace(expression, '%20');
   }
   const client = stringArray[5];
   const url = `https://storage.cloud.google.com/sray_out_docs_prod/enterprise/clients/${client}/Data/${stringArray[7]}/${stringArray[8]}/${stringArray[9]}`;
    return url;
  }

}
