 /* eslint-disable */
import { Injectable } from '@angular/core';
import { NewDocRegDocument } from 'app/fake-db/real-doc';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DispatcherTableService {
    private selectedEnterpriseDocuments = new BehaviorSubject<NewDocRegDocument[]>(null);
    private selectedPortfolioDocuments = new BehaviorSubject<NewDocRegDocument[]>(null);
    currentEnterpriseSelectedDocuments = this.selectedEnterpriseDocuments.asObservable();
    currentPortfolioSelectedDocuments = this.selectedPortfolioDocuments.asObservable();
  constructor() { }
    changeEnterpriseSelectedDocuments(documents: NewDocRegDocument[]): void {
        this.selectedEnterpriseDocuments.next(documents);
    }
    changePortfolioSelectedDocuments(documents: NewDocRegDocument[]): void {
        this.selectedPortfolioDocuments.next(documents);
    }
    
}
