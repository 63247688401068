 /* eslint-disable */
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import { Observable, Subject } from 'rxjs';
import {identifiers, DocumentColumns, gcEsg, preferences, dataFrequency, deliveryFrequency,
 temperatureE, catholicPreferences, productType, supplementalInfo, features, documentTypes, fileTypes, GeneratedFileInformation} from '../../../fake-db/form-helpers';
import * as _moment from 'moment';
import {CreateDocumentService} from '../../../services/create-document.service';
import { FormPart1, FormPart3, FormPart4 } from 'app/fake-db/real-doc';
import { DocumentsService } from 'app/services/documents.service';
import { Router } from '@angular/router';
import { SnackbarService } from 'app/main/shared/snackbar/snackbar.service';

// eslint-disable-next-line no-duplicate-imports
const moment =  _moment;

@Component({
  selector: 'app-form-wizard',
  templateUrl: './form-wizard.component.html',
  styleUrls: ['./form-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FormWizardComponent implements  OnInit, OnDestroy, AfterViewChecked, AfterViewInit {

    form: FormGroup;
    identifiers: DocumentColumns[] = identifiers;
    gcEsg: DocumentColumns[] = gcEsg;
    preferences: DocumentColumns[] = preferences;
    dataFrequency: DocumentColumns[] = dataFrequency;
    deliveryFrequency: DocumentColumns[] = deliveryFrequency;
    productType: DocumentColumns[] = productType;
    supplementalInfo: DocumentColumns[] = supplementalInfo;
    temperatureE: DocumentColumns[] = temperatureE;
    catholicPreferences: DocumentColumns[] = catholicPreferences;
    feat: DocumentColumns[] = features;
    fileTypes: DocumentColumns[] = fileTypes;
    // Horizontal Stepper
    horizontalStepperStep1: FormGroup;
    horizontalStepperStep2: FormGroup;
    horizontalStepperStep3: FormGroup;
    // create new document
    formStep1: FormGroup;
    formStep2: FormGroup;
    formStep3: FormGroup;
    formStep4: FormGroup;
  

    // optional stepper
    esgGC = false;
    temperature = false;
    prefFilter = false;
    features = false;
    catholic = false;
    // Private
    private unsubscribeAll: Subject<any>;
  
    constructor(
        private formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private docCreate: CreateDocumentService,
        private docService: DocumentsService,
        private router: Router,
        private snackbar: SnackbarService
    )
    {
        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }
   
   

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        
        // Reactive Form
        const defaultPref = this.preferences.map(a => a.value);
        const defaultESG = this.gcEsg.map(a => a.value);
        const defaultFeatures = this.feat.map(a => a.value);
        const defaultTemperature = ['temp_score_near', 'temp_score_far', 'temp_trend', 'temp_target', 'temp_scope3', 'temp_iea_sector'];
        this.formStep1 = this.formBuilder.group({
            name: [''],
            description: [''],
            clientName: [''],
            productType: ['', [Validators.required]] 
        });
        this.formStep2 = this.formBuilder.group({
            identifiers:  [['date', 'sedol', 'ticker', 'fsymid']],
            gcEsg: [defaultESG],
            temperatureE: [defaultTemperature],
            preferences: [defaultPref],
            catholicPreferences: [''],
            supplementalInfo: [''],
            features: [defaultFeatures]
        });
        this.formStep3 = this.formBuilder.group({
            fileName: [''],
            fileType: [''],
            splitByRowCount: ['3000000'],
            separator: [','],
            quoting: ['minimal'],
            nullRepresentation: [''],
            sray_version: ['sray:260']
        });
        this.formStep4 = this.formBuilder.group({
            dataEndDate: [''],
            dataStartDate: [''],
            dataFrequency:  [['daily']],
            dataHistory: [''],
            compress: [''],
            deliveryStartDate: [''],
            deliveryEndDate: [''],
            deliveryFrequency:  ['']
        });
      
    }
    ngAfterViewInit(): void {
        this.cd.detectChanges();
    }
    ngAfterViewChecked(): void {
        switch (this.formStep1.value.productType) {
            case 'esgGC':
              this.esgGC = true;
              this.temperature = false;
              this.prefFilter = false;
              this.features = false;
              this.catholic = false;
              this.cd.detectChanges();
              break;
            case 'temperature':
              this.temperature = true;
              this.esgGC = false;
              this.prefFilter = false;
              this.features = false;
              this.catholic = false;
              this.cd.detectChanges();
              break;
            case 'preferences':
              this.prefFilter = true;
              this.temperature = false;
              this.esgGC = false;
              this.features = false;
              this.catholic = false;
              this.cd.detectChanges();
              break;
            case 'features':
              this.features = true;
              this.temperature = false;
              this.esgGC = false;
              this.catholic = false;
              this.prefFilter = false;
              this.cd.detectChanges();
              break;
            case 'catholic':
              this.catholic = true;
              this.prefFilter = true;
              this.features = false;
              this.temperature = false;
              this.esgGC = false;
              this.cd.detectChanges();
              break;        
        }    
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Finish the horizontal stepper
     */
    finishHorizontalStepper(): void
    {
      
    
      
        alert('You have finished the horizontal stepper!');
        const dataEndDate = _moment(this.formStep4.value.dateEndDate).format('YYYY-MM-DD');
        const dataStartDate = _moment(this.formStep4.value.dataStartDate).format('YYYY-MM-DD');
        const deliveryStartDate = _moment(this.formStep4.value.deliveryStartDate).format('YYYY-MM-DD');
        const deliveryEndDate = _moment(this.formStep4.value.deliveryEndDate).format('YYYY-MM-DD');
        
        const partFour: FormPart4 = {
            dataEndDate, 
            dataStartDate, 
            dataFrequency: this.formStep4.value.dataFrequency,
            dataHistory: this.formStep4.value.dataHistory,
            compress: this.formStep4.value.compress,
            deliveryStartDate, 
            deliveryEndDate,
            deliveryFrequency:  this.formStep4.value.deliveryFrequency
        };
        this.docformatter(this.formStep1.value, this.formStep3.value).then(formattedDocument => {
            const partOne: FormPart1 = {
                name: formattedDocument.documentName,
                description: formattedDocument.documentDescription, 
                clientName: this.formStep1.value.clientName,
                productType: this.formStep1.value.productType
            };
            const partThree: FormPart3 = {
                fileName: formattedDocument.fileName,
                fileType: this.formStep3.value.fileType,
                splitByRowCount: this.formStep3.value.splitByRowCount,
                separator: this.formStep3.value.separator,
                quoting: this.formStep3.value.quoting,
                nullRepresentation: this.formStep3.value.nullRepresentation,
                sray_version: this.formStep3.value.sray_version
            };
            this.docCreate.createDocumentFormat(partOne, this.formStep2.value, partThree, partFour)
            .subscribe(data => {
                console.log(data);
                this.snackbar.openSnackBar('Document created');
                window.location.reload();
             });
        });
       
    
    }
   

   private docformatter(partOne: FormPart1, partThree: FormPart3) : Promise<GeneratedFileInformation> {
    const todaysDate = _moment().format('YYYY-MM-DD');
        const formattedDoc = {
        } as GeneratedFileInformation;
        return new Promise(resolve => {
            switch(partOne.productType) {
                case 'esgGC':
                  partThree.fileName === '' ?
                  formattedDoc.fileName = `sray_${partThree.sray_version}_${partThree.fileType}_${todaysDate}.csv`
                : formattedDoc.fileName = partThree.fileName;

                partOne.name === '' ?
                formattedDoc.documentName = `SRay_Enterprise_${partThree.sray_version}_${partOne.clientName}`
                : formattedDoc.documentName = partOne.name;
                
                partOne.description === '' ?
                formattedDoc.documentDescription = `S-Ray Enterprise (ESG/GC) for ${partOne.clientName}`
                : formattedDoc.documentDescription = partOne.description;     
                break;
                case 'temperature':
                    partThree.fileName === '' ?
                    formattedDoc.fileName = `sray_temperature_${partThree.sray_version}_${partThree.fileType}_${todaysDate}.csv`
                  : formattedDoc.fileName = partThree.fileName;
  
                  partOne.name === '' ?
                  formattedDoc.documentName = `SRay_Enterprise_Temperature_${partThree.sray_version}_${partOne.clientName}`
                  : formattedDoc.documentName = partOne.name;
                  
                  partOne.description === '' ?
                  formattedDoc.documentDescription = `S-Ray Enterprise (Temperature Score) for ${partOne.clientName}`
                  : formattedDoc.documentDescription = partOne.description;
                break;
                case 'preferences':
                    partThree.fileName === '' ?
                    formattedDoc.fileName = `sray_preferences_${partThree.sray_version}_${partThree.fileType}_${todaysDate}.csv`
                  : formattedDoc.fileName = partThree.fileName;
  
                  partOne.name === '' ?
                  formattedDoc.documentName = `SRay_Enterprise_Preferences_${partThree.sray_version}_${partOne.clientName}`
                  : formattedDoc.documentName = partOne.name;
                  
                  partOne.description === '' ?
                  formattedDoc.documentDescription = `S-Ray Enterprise (Preferences Filter) for ${partOne.clientName}`
                  : formattedDoc.documentDescription = partOne.description;
                break;
                case 'features':
                    partThree.fileName === '' ?
                    formattedDoc.fileName = `sray_features_${partThree.sray_version}_${partThree.fileType}_${todaysDate}.csv`
                  : formattedDoc.fileName = partThree.fileName;
  
                  partOne.name === '' ?
                  formattedDoc.documentName = `SRay_Enterprise_Features_${partThree.sray_version}_${partOne.clientName}`
                  : formattedDoc.documentName = partOne.name;
                  
                  partOne.description === '' ?
                  formattedDoc.documentDescription = `S-Ray Enterprise (Feature Layer) for ${partOne.clientName}`
                  : formattedDoc.documentDescription = partOne.description;  
                break;
                case 'catholic':
                    partThree.fileName === '' ?
                    formattedDoc.fileName = `sray_catholic_${partThree.sray_version}_${partThree.fileType}_${todaysDate}.csv`
                  : formattedDoc.fileName = partThree.fileName;
  
                  partOne.name === '' ?
                  formattedDoc.documentName = `SRay_Enterprise_Catholic_${partThree.sray_version}_${partOne.clientName}`
                  : formattedDoc.documentName = partOne.name;
                  
                  partOne.description === '' ?
                  formattedDoc.documentDescription = `S-Ray Enterprise (Catholic Filter) for ${partOne.clientName}`
                  : formattedDoc.documentDescription = partOne.description;
                break;
            }
            resolve(formattedDoc);
        });
       
   }

 

}
