<h2 mat-dialog-title>Dispatch New Portfolio Documents</h2>
<mat-dialog-content class="mat-typography">
    <app-dispatch-portfolio-wizard></app-dispatch-portfolio-wizard>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button color="primary" mat-dialog-close (click)="submit()">DISPATCH</button>
</mat-dialog-actions>
<mat-dialog-actions align="end">
    <button mat-button  mat-dialog-close>CLOSE</button>
  </mat-dialog-actions>
