 /* eslint-disable */
import { Injectable } from '@angular/core';
import {  allAddtionalDataFields, DataField } from 'app/fake-db/data-fields';
import {allGcEsgDataFields} from 'app/fake-db/esg-gc';
import {allTemperatureDataFields} from 'app/fake-db/temperature';
import {allPreferencesDataFields} from 'app/fake-db/preferences';
import {allCatholicDataFields} from 'app/fake-db/catholic';
import {allFeatureDataFields} from 'app/fake-db/features';
import { CoverageField, FormPart1, FormPart2, FormPart3, FormPart4, NewDocRegDocument, authToken } from 'app/fake-db/real-doc';
import * as _moment from 'moment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { GeneratedFileInformation } from 'app/fake-db/form-helpers';
@Injectable({
  providedIn: 'root'
})
export class CreateDocumentService {
dataFields: DataField[] = [];
authToken: string = sessionStorage.getItem('token');

  constructor(private http: HttpClient) { }

  createDocumentFormat(partOne: FormPart1,
                       partTwo: FormPart2, partThree: FormPart3, partFour: FormPart4): Observable<NewDocRegDocument> {
       
        
        
         let answer = 'yes';
         if (!partFour.compress === true){
            answer = 'no';
         }

         
         
         const newDocument: NewDocRegDocument = {
             name: partOne.name,
             description: partOne.description,
             meta: {
                client_name:  partOne.clientName,
                data_frequency: partFour.dataFrequency,
                delivery_frequency: partFour.deliveryFrequency,
                delivery_start_date: partFour.deliveryStartDate,
                delivery_end_date: partFour.deliveryEndDate,
                data_start_date: partFour.dataStartDate,
                data_end_date: partFour.dataEndDate,
                data_history: partFour.dataHistory,
                dev_sray_service: 'enterprise',
                file_name: partThree.fileName,
                fill_na: partThree.nullRepresentation,
                separator: partThree.separator,
                split_by_rowcount: partThree.splitByRowCount,
                sray_version: partThree.sray_version,
                template: 'PlainTextDelivery',
                compress: answer,
                data_fields: this.createDataFields(partOne, partTwo)
             },
             tags: ['docbuilder']
         };
         
         if (partOne.productType === 'esgGC') {
             newDocument.meta.coverage_fields = 
             [
                {
                    app_name: 'ESG',
                    app_version: [
                        2,
                        6,
                        2,
                        0
                    ],
                    name: 'esg'
                },
                {
                    app_name: 'GC',
                    app_version: [
                        2,
                        6,
                        2,
                        0
                    ],
                    name: 'gc'
                }
             ];
         }
  
      
        
         return this.docApi(newDocument);
}
private createDataFields(partOne: FormPart1,
                         partTwo: FormPart2): DataField[] {
     const dataFields: DataField[] = [];                            
     switch (partOne.productType) {
            case 'esgGC':
            partTwo.gcEsg.forEach(e => {
            if (allGcEsgDataFields[e]) {
                dataFields.push(allGcEsgDataFields[e]);
            }          
            });
            partTwo.identifiers.forEach(e => {
                if (allAddtionalDataFields[e]) {
                    dataFields.push(allAddtionalDataFields[e]);
                }   
            });
            if (partTwo.supplementalInfo) {
                partTwo.supplementalInfo.forEach(e => {
                    if (allAddtionalDataFields[e]) {
                        dataFields.push(allAddtionalDataFields[e]);
                    }   
                });    
            }
           
            break;
              case 'temperature':
               
                  partTwo.temperatureE.forEach(e => {
                    if (allTemperatureDataFields[e]) {
                        dataFields.push(allTemperatureDataFields[e]);
                    }
                  });
                  partTwo.identifiers.forEach(e => {
                    if (allAddtionalDataFields[e]) {
                        dataFields.push(allAddtionalDataFields[e]);
                    }   
                });
                  if (partTwo.supplementalInfo) {
                    partTwo.supplementalInfo.forEach(e => {
                        if (allAddtionalDataFields[e]) {
                            dataFields.push(allAddtionalDataFields[e]);
                        }   
                    });    
                }
               
                  break;
              case 'preferences':
                partTwo.preferences.forEach(e => {
                    if (allPreferencesDataFields[e]) {
                        if (allPreferencesDataFields[e].length > 0) {
                            dataFields.push(...allPreferencesDataFields[e]);
                        } else {
                            dataFields.push(allPreferencesDataFields[e]);
                        }
                        
                    }
                  });

                partTwo.identifiers.forEach(e => {
                    if (allAddtionalDataFields[e]) {
                        dataFields.push(allAddtionalDataFields[e]);
                    }   
                });
                if (partTwo.supplementalInfo) {
                    partTwo.supplementalInfo.forEach(e => {
                        if (allAddtionalDataFields[e]) {
                            dataFields.push(allAddtionalDataFields[e]);
                        }   
                    });    
                }
                break;
              case 'features':
                partTwo.features.forEach(e => {
                    if (allFeatureDataFields[e]) {
                        dataFields.push(allFeatureDataFields[e]);
                    }   
                });
                partTwo.identifiers.forEach(e => {
                    if (allAddtionalDataFields[e]) {
                        dataFields.push(allAddtionalDataFields[e]);
                    }   
                });
                if (partTwo.supplementalInfo) {
                    partTwo.supplementalInfo.forEach(e => {
                        if (allAddtionalDataFields[e]) {
                            dataFields.push(allAddtionalDataFields[e]);
                        }   
                    });    
                }

                
                break;
              case 'catholic':
                partTwo.catholicPreferences.forEach(e => {
                    if (allCatholicDataFields[e]) {
                        dataFields.push(allCatholicDataFields[e]);
                    }
                });  
                partTwo.preferences.forEach(e => {
                    if (allPreferencesDataFields[e].length > 0) {
                        dataFields.push(...allPreferencesDataFields[e]);
                    } else {
                        dataFields.push(allPreferencesDataFields[e]);
                    }
                  });
                partTwo.identifiers.forEach(e => {
                    if (allAddtionalDataFields[e]) {
                        dataFields.push(allAddtionalDataFields[e]);
                    }   
                });
                if (partTwo.supplementalInfo) {
                    partTwo.supplementalInfo.forEach(e => {
                        if (allAddtionalDataFields[e]) {
                            dataFields.push(allAddtionalDataFields[e]);
                        }   
                    });    
                }
                break;  
        }
     return dataFields;
        
    }
   private docApi(doc: NewDocRegDocument): Observable<any> {
    const httpOptions = { 
        headers: new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        })
    };
  
    return this.http.post('http://127.0.0.1:8080/doc-registry/api/v1/document/', doc, httpOptions)
    .pipe(
        catchError(this.handleError)
    );
   } 
   private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
  
   
     
    
}
