<div id="order" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-16" [routerLink]="'/enterprise'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2">
                        {{delivery.id}} 
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <button mat-raised-button
                    class="save-product-button"
                    [disabled]="form.invalid || form.pristine"
                     (click)="saveDelivery()">
                <span>SAVE DELIVERY</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">
                <form  name="form" [formGroup]="form" class="product w-100-p" fxLayout="column" fxFlex>
                    <mat-tab-group fxLayout="column" fxFlex>


                        <mat-tab label="Primary Info">
                           
                                <div class="products tab-content p-24" fusePerfectScrollbar>
                                    
                                    </div>
                          
                           
                        </mat-tab>
                        <mat-tab label="Email">
                          
                                <div class="products tab-content p-24" >
                                  
                                    </div>
                           
                           
                        </mat-tab>
                        <mat-tab label="S3">
                           
                                <div class="products tab-content p-24" fusePerfectScrollbar>
                                 
                                    </div>
                          
                           
                        </mat-tab>
                        <mat-tab label="FTP">
                         
                                <div class="products tab-content p-24" fusePerfectScrollbar>
                                   
                                    </div>
                     
                           
                        </mat-tab>
                        <mat-tab label="SFTP">
                         
                            <div class="products tab-content p-24" fusePerfectScrollbar>
                               
                                </div>
                 
                       
                    </mat-tab>
                        
                    </mat-tab-group>
            </form>
         

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->
</div>