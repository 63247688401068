import { AfterViewChecked, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NewDocRegDocument } from 'app/fake-db/real-doc';
import { DispatcherTableService } from '../dispatcher-table/dispatcher-table.service';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {deliveryJobTypes} from 'app/fake-db/form-helpers';
import { fromPairs } from 'lodash';
@Component({
  selector: 'app-dispatch-form-wizard',
  templateUrl: './dispatch-form-wizard.component.html',
  styleUrls: ['./dispatch-form-wizard.component.scss']
})
export class DispatchFormWizardComponent implements OnInit, AfterViewChecked {
selectedDocuments: NewDocRegDocument[];
visible = true;
selectable = true;
removable = true;
addOnBlur = true;
readonly separatorKeysCodes: number[] = [ENTER, COMMA];
dispatchForm: FormGroup;
deliveryJobTypes = deliveryJobTypes;
emailDeliveryType = false;
s3DeliveryType = false;
ftpDeliveryType = false;
sftpDeliveryType = false;
  constructor(private dispatch: DispatcherTableService, private formBuilder: FormBuilder,
              private cd: ChangeDetectorRef) { }

  ngOnInit(): void {
     this.dispatch.currentEnterpriseSelectedDocuments.subscribe(data => this.selectedDocuments = data);
     this.dispatchForm = this.formBuilder.group({
         comment: [''],
         deliveryJobType: [''],
         name: [''],
         awsAccessKeyId: [''],
         awsSecretAccessKey: [''],
         awsRegion: [''],
         awsBucket: [''],
         awsPath: [''],
         emailFrom: ['', Validators.email],
         emailTo: ['', Validators.email],
         emailSubject: [''],
         emailBody: [''],
         ftpUsername: [''],
         ftpPassword: [''],
         ftpHost: [''],
         ftpPort: [''],
         ftpPath: [''],
         sftpUsername: [''],
         sftpPassword: [''],
         sftpHost: [''],
         sftpPort: [''],
         sftpPath: ['']
     });
  }


  remove(doc: NewDocRegDocument): void {
    const index = this.selectedDocuments.indexOf(doc);

    if (index >= 0) {
      this.selectedDocuments.splice(index, 1);
    }
  }
  ngAfterViewChecked(): void {
      switch (this.dispatchForm.value.deliveryJobType) {
        case 'DeliveryEmailJob':
          this.emailDeliveryType = true;
          this.s3DeliveryType = false;
          this.ftpDeliveryType = false;
          this.sftpDeliveryType = false;
          this.cd.detectChanges();
          break;
        case 'DeliveryAWSS3Job':
          this.s3DeliveryType = true;
          this.emailDeliveryType = false;
          this.ftpDeliveryType = false;
          this.sftpDeliveryType = false;
          this.cd.detectChanges();
          break;
        case 'DeliveryFTPJob':
          this.ftpDeliveryType = true;
          this.emailDeliveryType = false;
          this.s3DeliveryType = false;
          this.sftpDeliveryType = false;
          this.cd.detectChanges();
          break;
        case 'DeliverySFTPJob':
          this.sftpDeliveryType = true;
          this.emailDeliveryType = false;
          this.s3DeliveryType = false;
          this.ftpDeliveryType = false;
          this.cd.detectChanges();
          break;
       
    }    
}
}
