import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { NewDocRegDocument, PortfolioDocument } from 'app/fake-db/real-doc';
import { DeleteDocumentService } from 'app/services/delete-document.service';
import { SnackbarService } from '../snackbar/snackbar.service';

@Component({
  selector: 'app-delete-document-modal',
  templateUrl: './delete-document-modal.component.html'
})
export class DeleteDocumentModalComponent implements OnInit {
enterpriseDocument: NewDocRegDocument;
portfolioDocument: PortfolioDocument;  
    constructor(private deleteDoc: DeleteDocumentService,
                private router: Router,
                private snackbar: SnackbarService,
                public dialogRef: MatDialogRef<DeleteDocumentModalComponent>,
                @Inject(MAT_DIALOG_DATA) public data: any) {
                    if (data.meta.dev_sray_service === 'enterprise') {
                        this.enterpriseDocument = this.data;
                    } else if (data.meta.dev_sray_service === 'pftscreen') {
                        this.portfolioDocument = this.data;
                    }
                }

  ngOnInit(): void {
  }
  
  onNoClick(): void {
    this.dialogRef.close();
  }
  deleteDocument(): void {
      if (this.enterpriseDocument) {
        this.deleteDoc.deleteEnterpriseDocument(this.enterpriseDocument).subscribe(data => {
            
            this.snackbar.openSnackBar(`Deleted ${this.enterpriseDocument.id}`);
            this.router.navigateByUrl('/enterprise');
        });
      } else {
          this.deleteDoc.deletePortfolioDocument(this.portfolioDocument).subscribe(data => {
            this.snackbar.openSnackBar(`Deleted ${this.portfolioDocument.id}`);
            this.router.navigateByUrl('/portfolio');
          });
      }
   
  }

}
