 /* eslint-disable */
export interface SrayApps {
     viewValue: string;
     version: number[];
     appName: string;
  
}
export const apps: SrayApps[] = [
    {viewValue: 'Preferences Filter', appName: 'PreferencesFilter', version: [3, 0, 0, 0] },
    {viewValue: 'Temperature', appName: 'temperature', version: [1, 1, 0, 0]},
    {viewValue: 'Catholic Filter', appName: 'CatholicFilter', version: [1, 0, 0, 0]},
    {viewValue: 'Controversial Weapons', appName: 'ControversialWeapons', version: [1, 0, 0, 0]},
    {viewValue: 'ESG', appName: 'ESG', version: [2, 6, 2, 0]},
    {viewValue: 'Feature Total', appName: 'FeatureTotal', version: [2, 6, 2, 0]},
    {viewValue: 'GC', appName: 'GC', version: [2, 6, 2, 0]},
    {viewValue: 'Preferences Filter Flag_5_inv', appName: 'PreferencesFilterFlag_5_inv', version: [3, 0, 0, 0]},
    {viewValue: 'SRay Mktcap', appName: 'sray_mktcap', version: [2, 6, 1, 0]},
    {viewValue: 'Temperature Emissions', appName: 'temperature_emissions', version: [1, 1, 0, 0]},
    {viewValue: 'Temperature Financials', appName: 'temperature_financials', version: [1, 1, 0, 0]},
    {viewValue: 'Temperature Financials', appName: 'temperature_financials', version: [1, 1, 0, 0]},
    {viewValue: 'Temperature Sbti', appName: 'temperature_sbti', version: [1, 1, 0, 0]},
    {viewValue: 'AssetInfo Name', appName: 'assetinfo_name', version: [1, 0, 0, 0]},
    {viewValue: 'AssetInfo Enity', appName: 'assetinfo_entity', version: [1, 0, 0, 0]},
    {viewValue: 'AssetInfo Security ID', appName: 'assetinfo_security_id', version: [1, 0, 0, 0]},
    {viewValue: 'AssetInfo Activity', appName: 'assetinfo_activity', version: [1, 0, 0, 0]},
    {viewValue: 'AssetInfo Dom Country', appName: 'assetinfo_dom_country', version: [1, 0, 0, 0]},
    {viewValue: 'AssetInfo Exchange Country', appName: 'assetinfo_exch_country', version: [1, 0, 0, 0]},
    {viewValue: 'AssetInfo Exchange', appName: 'assetinfo_exchange', version: [1, 0, 0, 0]},
    
];
