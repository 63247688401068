 /* eslint-disable */
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { HttpClient, HttpHeaders  } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import {authToken, DocRegDocument, Document, DocumentData, NewDocRegDocument} from '../fake-db/real-doc';
import { map } from 'rxjs/operators';
import { tap } from 'lodash';
@Injectable({
  providedIn: 'root'
})
export class DocumentsService implements Resolve<any> {
    routeParams: any;
    public documents: NewDocRegDocument[] = undefined;
    onDocumentChanged: BehaviorSubject<any>;
    authToken: string = sessionStorage.getItem('token');
  constructor(private http: HttpClient) {
    this.onDocumentChanged = new BehaviorSubject({});
   }
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        console.log('in resolve');
        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDocuments()
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getSavedDocuments(): Observable<NewDocRegDocument[]> {
        console.log('fetching saved documents');
        return of(this.documents);
    }
    getDocuments(): Promise<any> {
        const authHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        });

        return new Promise((resolve, reject) => {
            this.http.get('http://127.0.0.1:8080/doc-registry/api/v1/document/',
            {headers: authHeaders})
                .subscribe((response: any) => {
                    this.documents = this.onlyEnterpriseDocs(response);
                    this.onDocumentChanged.next(this.documents);
                    resolve(response);
                }, reject);
        });
    }
    private onlyEnterpriseDocs(documents: NewDocRegDocument[]): NewDocRegDocument[] {
        const enterprisedocs = [];
        documents.map(a => {
            if (a.meta.dev_sray_service === 'enterprise'){
                enterprisedocs.push(a);
            }
        });
        return enterprisedocs;
    }


}
