import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dispartch-portfolio-modal',
  templateUrl: './dispartch-portfolio-modal.component.html',
  styleUrls: ['./dispartch-portfolio-modal.component.scss']
})
export class DispartchPortfolioModalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  submit(): void {
    alert('submitted');
  }

}
