/* eslint-disable */
import {DataField} from './data-fields';
export const allFeatureDataFields = {
    feat_be: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_be'
        }
    } as DataField,
    feat_cap_str: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_cap_str'
        }
    } as DataField,
    feat_cg_boa: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_cg_boa'
        }
    } as DataField,
    feat_cg_tra: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_cg_tra'
        }
    } as DataField,
    feat_e_air: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_e_air'
        }
    } as DataField,
    feat_e_man: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_e_man'
        }
    } as DataField,
    feat_e_oth: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_e_oth'
        }
    } as DataField,
    feat_es_bio: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_es_bio'
        }
    } as DataField,
    feat_for_acc: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_for_acc'
        }
    } as DataField,
    feat_hc_com: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_com'
        }
    } as DataField,
    feat_hc_div: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_div'
        }
    } as DataField,
    feat_hc_emp: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_emp'
        }
    } as DataField,
    feat_hc_hri: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_hri'
        }
    } as DataField,
    feat_hc_lri: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_lri'
        }
    } as DataField,
    feat_hc_ohs: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_ohs'
        }
    } as DataField,
    feat_hc_trd: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_hc_trd'
        }
    } as DataField,
    feat_pl_qas: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_pl_qas'
        }
    } as DataField,
    feat_ru_ene: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_ru_ene'
        }
    } as DataField,
    feat_ru_wat: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_ru_wat'
        }
    } as DataField,
    feat_s_ene: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_s_ene'
        }
    } as DataField,
    feat_sr_com: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_sr_com'
        }
    } as DataField,
    feat_sr_pra: {
        field: {
            app_name: 'FeatureTotal',
            app_version: [2, 6, 2, 0],
            name: 'feat_sr_pra'
        }
    } as DataField,
};
