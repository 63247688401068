<!-- SIDE PANEL -->
<ng-container *ngIf="!AppConfig.layout.sidepanel.hidden && AppConfig.layout.sidepanel.position === 'left'">

    <!-- PANEL CONTENT -->

</ng-container>
<!-- / SIDE PANEL -->

<div id="main">

    <!-- NAVBAR: Top -->
    <ng-container>
        <ng-container *ngTemplateOutlet="topNavbar"></ng-container>
    </ng-container>
    <!-- / NAVBAR: Top -->

    <div id="container-1" class="container">

       

        <div id="container-2" class="container">

            <div id="container-3" class="container" fusePerfectScrollbar
                 [fusePerfectScrollbarOptions]="{suppressScrollX: true, updateOnRouteChange : true}">

                <!-- CONTENT -->
                <content></content>
                <!-- / CONTENT -->

            </div>

        </div>

    </div>

</div>





<!-- ----------------------------------------------------------------------------------------------------- -->
<!-- @ PARTIALS                                                                                            -->
<!-- ----------------------------------------------------------------------------------------------------- -->




<!-- TOP NAVBAR -->
<ng-template #topNavbar>
    <navbar variant="horizontal-style-1"
            class="top-navbar" fxHide fxShow.gt-md
            *ngIf="!AppConfig.layout.navbar.hidden">
    </navbar>
</ng-template>
<!-- / TOP NAVBAR -->

