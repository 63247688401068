<div class="table-container">
    <mat-table class="dispatch-table"
    #table [dataSource]="this.dataSource"
    matSort
    [@animateStagger]="{value:'50'}"
    fusePerfectScrollbar>
    
    <!-- Checkbox Column -->
    <ng-container matColumnDef="select" id="checkbox-column">
        <th mat-header-cell *matHeaderCellDef>
          <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue() && isAllSelected()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()"
                        [aria-label]="checkboxLabel()">
          </mat-checkbox>
        </th>
        <td mat-cell *matCellDef="let row">
          <mat-checkbox (click)="$event.stopPropagation(); selectedDocuments()"
                        (change)="$event ? selection.toggle(row) : null; selectedDocuments()"
                        [checked]="selection.isSelected(row)"
                        [aria-label]="checkboxLabel(row)">
          </mat-checkbox>
        </td>
      </ng-container>
    <!-- ID Column -->
    <ng-container matColumnDef="id">
    <mat-header-cell *matHeaderCellDef mat-sort-header>ID</mat-header-cell>
    <mat-cell *matCellDef="let element">
     <p class="text-truncate">{{element.id}}</p>
    </mat-cell>
    </ng-container>
    
    <!-- Client Name Column -->
    <ng-container matColumnDef="meta.client_name">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Client Name</mat-header-cell>
    <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm>
     <p class="text-truncate">{{element.meta.client_name}}</p>
    </mat-cell>
    </ng-container>
    
    <!-- Name Column -->
    <ng-container matColumnDef="name">
    <mat-header-cell *matHeaderCellDef mat-sort-header>Document Name</mat-header-cell>
    <mat-cell *matCellDef="let element">
     <p class="text-truncate">
        {{element.name}}
     </p>
    </mat-cell>
    </ng-container>
    
    <!-- Date -->
    <ng-container matColumnDef="date_modified">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-md> Date Modified</mat-header-cell>
    <mat-cell *matCellDef="let element" fxHide fxShow.gt-md>
     <p class="text-truncate">
        {{element.date_modified |  date: 'yyyy-MM-dd'}}
     </p>
    </mat-cell>
    </ng-container>
    
    <!-- Active Column -->
    <ng-container matColumnDef="active">
    <mat-header-cell *matHeaderCellDef mat-sort-header fxHide fxShow.gt-sm>Active</mat-header-cell>
    <mat-cell *matCellDef="let element" fxHide fxShow.gt-sm>
     <p class="text-truncate">
        {{element.active}}
     </p>
    </mat-cell>
    </ng-container>
    
    <ng-container matColumnDef="edit">
        <th mat-header-cell *matHeaderCellDef> Edit </th>
        <td mat-cell *matCellDef="let element">
          
                <button mat-icon-button color="primary" aria-label="Example icon button with a home icon" (click)="edit($event, element)">
                    <mat-icon>edit</mat-icon>
                  </button>
         
         
        </td>
      </ng-container>
    
    
    <mat-header-row class="headers" *matHeaderRowDef="displayedColumns; sticky:true"></mat-header-row>
    
    <mat-row *matRowDef="let order; columns: displayedColumns;"
      class="order"
      >
    </mat-row>
    </mat-table>
    
    <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>
</div>
