<div id="login" fxLayout="column">

    <div id="login-form-wrapper" fxLayout="column" fxLayoutAlign="center center">

        <div id="login-form" [@animate]="{value:'*',params:{duration:'300ms',y:'100px'}}">

            <div class="logo">
                <img width="300px" src="assets/images/logos/2. arabesque_2020_w.png">
            </div>
            <mat-card class="login-card" >
              

                <form name="loginForm" [formGroup]="loginForm" (ngSubmit)="login()">
                    <div fxFlex="1 0 auto" fxLayout="column">
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Email</mat-label>
                        <input matInput formControlName="email">
                        <mat-icon matSuffix class="secondary-text">mail</mat-icon>
                        <mat-error *ngIf="loginForm.get('email').hasError('required')">
                            Email is required
                        </mat-error>
                        <mat-error
                            *ngIf="!loginForm.get('email').hasError('required') &&
                                    loginForm.get('email').hasError('email')">
                            Please enter a valid email address
                        </mat-error>
                    </mat-form-field>
    
                    <mat-form-field appearance="outline" fxFlex="100">
                        <mat-label>Password</mat-label>
                        <input matInput type="password" formControlName="password">
                        <mat-icon matSuffix class="secondary-text">vpn_key</mat-icon>
                        <mat-error>
                            Password is required
                        </mat-error>
                    </mat-form-field>
                    
                    <button type="submit" mat-raised-button color="accent" class="submit-button" aria-label="LOG IN"
                            [disabled]="loginForm.invalid">
                        LOGIN
                    </button>
                    </div>
    
    
    
                </form>
            </mat-card>
     


        </div>

    </div>

</div>
