 <!-- REACTIVE FORM EXAMPLE -->

 <form formGroupName="documentDelivery" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
 fxFlex="1 0 auto">

 <div class="h2 mb-24">Reactive Form Example</div>


 <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline"  fxFlex="100">
        <mat-label>Data Start Date</mat-label>
        <input matInput [matDatepicker]="dataStartDate" name="dataStartDate" formControlName="dataStartDate">
        <mat-datepicker-toggle matSuffix [for]="dataStartDate" ></mat-datepicker-toggle>
        <mat-datepicker  #dataStartDate></mat-datepicker>
    </mat-form-field>
 </div>
 <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline"  fxFlex="100">
        <mat-label>Data End Date</mat-label>
        <input matInput [matDatepicker]="dataEndDate" name="dataEndDate" formControlName="dataEndDate">
        <mat-datepicker-toggle matSuffix [for]="dataEndDate" ></mat-datepicker-toggle>
        <mat-datepicker  #dataEndDate></mat-datepicker>
    </mat-form-field>
 </div>

   
    <div fxFlex="1 0 auto" fxLayout="row">
        <mat-form-field appearance="outline" fxFlex="100">
            <mat-label>Data Frequency</mat-label>
            <mat-select formControlName="dataFrequency" >
                <mat-option *ngFor="let data of allDataFrequency" [value]="data.value">
                    {{data.viewValue}}
                  </mat-option>
            </mat-select>
        </mat-form-field>
    </div>
  
    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Data History </mat-label>
        <input matInput formControlName="dataHistory" >
        <mat-error> Data History is required!</mat-error>
    </mat-form-field>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row">
        <div class="pl-4 mb-8" fxFlex="100">
            <mat-slide-toggle [labelPosition]="'before'" formControlName="compress">Compress</mat-slide-toggle>
        </div>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline"  fxFlex="100">
        <mat-label>Delivery Start Date</mat-label>
        <input matInput [matDatepicker]="deliveryStartDate" name="deliveryStartDate" formControlName="deliveryStartDate">
        <mat-datepicker-toggle matSuffix [for]="deliveryStartDate" ></mat-datepicker-toggle>
        <mat-datepicker  #deliveryStartDate></mat-datepicker>
    </mat-form-field>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline"  fxFlex="100">
        <mat-label>Delivery End Date</mat-label>
        <input matInput [matDatepicker]="deliveryEndDate" name="deliveryEndDate" formControlName="deliveryEndDate">
        <mat-datepicker-toggle matSuffix [for]="deliveryEndDate"></mat-datepicker-toggle>
        <mat-datepicker  #deliveryEndDate></mat-datepicker>
    </mat-form-field>
    </div>
    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100">
        <mat-label>Delivery Frequency</mat-label>
        <mat-select formControlName="deliveryFrequency">
            <mat-option *ngFor="let del of allDeliveryFrequency" [value]="del.value">
                {{del.viewValue}}
              </mat-option>
        </mat-select>
    </mat-form-field>


</div>
 </form>




<!-- / REACTIVE FORM EXAMPLE -->