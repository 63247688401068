 /* eslint-disable */
import { AfterViewChecked, AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { arabesqueAnimations } from '@kav/animations';
import { Deliveries } from 'app/fake-db/deliveries';
import { NewDocRegDocument, PortfolioDocument } from 'app/fake-db/real-doc';
import { FilterDataService } from 'app/services/filter-data.service';
import { from } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { DeliveriesService } from '../deliveries.service';

@Component({
  selector: 'app-deliveries-table',
  templateUrl: './deliveries-table.component.html',
  styleUrls: ['./deliveries-table.component.scss'],
  animations: arabesqueAnimations
})
export class DeliveriesTableComponent implements OnInit, AfterViewInit, AfterViewChecked {
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;
    @Input() productType: string;
    @Input() document: NewDocRegDocument | PortfolioDocument;
     displayedColumns: string[] = ['id', 'path', 'file_name','active',  'date_modified', 'file'];

    dataSource: any;
   
  constructor(private route: Router, private deliveries: DeliveriesService, 
              private filterService: FilterDataService ) {
                
               
   }

  ngOnInit(): void {
    
   
    this.dataSource  = new MatTableDataSource(this.deliveries.deliveries);
    
  }
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  ngAfterViewChecked(): void {
    this.filterService.selectedFilterValue.subscribe(data => this.dataSource.filter = data);
}


}
