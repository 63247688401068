 /* eslint-disable */
import { AfterViewChecked, AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { DocumentColumns, identifiers, gcEsg, preferences, dataFrequency, deliveryFrequency, productType, supplementalInfo, temperatureE, catholicPreferences, documentTypes, features, portfolioDeliveryFrequency, portfolioFeatures } from 'app/fake-db/form-helpers';
import {apps, SrayApps} from 'app/fake-db/app-versions';
import { FormPart4, PFormPart1, PFormPart2, PFormPart3, PFormPart4, PortfolioDocument } from 'app/fake-db/real-doc';
import { CreateDocumentService } from 'app/services/create-document.service';
import { DocumentsService } from 'app/services/documents.service';
import {CreatePortfolioDocumentService} from 'app/services/create-portfolio-document.service';
import * as _moment from 'moment';
import { Subject } from 'rxjs';
import { SnackbarService } from 'app/main/shared/snackbar/snackbar.service';
const moment =  _moment;
@Component({
  selector: 'app-portfolio-wizard',
  templateUrl: './portfolio-wizard.component.html',
  styleUrls: ['./portfolio-wizard.component.scss'],
  encapsulation: ViewEncapsulation.None
  
})
export class PortfolioWizardComponent implements  OnInit, OnDestroy {
 
    portfolioForm: FormGroup;
    identifiers: DocumentColumns[] = identifiers;
    deliveryFrequency: DocumentColumns[] = portfolioDeliveryFrequency;
    snapshots: DocumentColumns[] = portfolioFeatures;
    srayApps: SrayApps[] = apps;

    // create new document
    portfolioFormStep1: FormGroup;
    portfolioFormStep2: FormGroup;
    portfolioFormStep3: FormGroup;
    portfolioFormStep4: FormGroup;
  

  
    // Private
    private unsubscribeAll: Subject<any>;
 

    constructor(
        private formBuilder: FormBuilder,
        private cd: ChangeDetectorRef,
        private docCreate: CreateDocumentService,
        private docService: CreatePortfolioDocumentService,
        private router: Router,
        private snackbar: SnackbarService
       
    )
    {
        // Set the private defaults
        this.unsubscribeAll = new Subject();
    }
  
   

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        // Reactive Form

        this.portfolioFormStep1 = this.formBuilder.group({
            name: [''],
            description: [''],
            clientName: [''],
            srayApps: ['']
        });
        this.portfolioFormStep2 = this.formBuilder.group({
            identifiers:  [''],
            portfolio: [''],
            portfolioLabel: [''],
            benchmark: [''],
            benchmarkLabel: [''],
            snapshots: ['']
        });
        this.portfolioFormStep3 = this.formBuilder.group({
            fileName: [''],
            sray_version: ['sray:260']
        });
        this.portfolioFormStep4 = this.formBuilder.group({
            deliveryStartDate: [''],
            deliveryEndDate: [''],
            deliveryFrequency:  ['']
        });
      
    }

  
    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this.unsubscribeAll.next();
        this.unsubscribeAll.complete();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Finish the horizontal stepper
     */
    finishHorizontalStepper(): void
    {
        const dataEndDate = _moment(this.portfolioFormStep4.value.dateEndDate).format('YYYY-MM-DD');
        const dataStartDate = _moment(this.portfolioFormStep4.value.dataStartDate).format('YYYY-MM-DD');
        const deliveryStartDate = _moment(this.portfolioFormStep4.value.deliveryStartDate).format('YYYY-MM-DD');
        const deliveryEndDate = _moment(this.portfolioFormStep4.value.deliveryEndDate).format('YYYY-MM-DD');
        const partFour: PFormPart4 = {
         dataEndDate,
         dataStartDate,
          deliveryStartDate, 
          deliveryEndDate,
          deliveryFrequency:  this.portfolioFormStep4.value.deliveryFrequency
      };
      this.pfDocFormatter(this.portfolioFormStep1.value,
        this.portfolioFormStep2.value,
        this.portfolioFormStep3.value).then((data) =>{
          
            this.docService.createPortfolioDocumentFormat(data.partOne,
                this.portfolioFormStep2.value,
                data.partThree,
               partFour).subscribe(result =>{
                console.log(result);
                this.snackbar.openSnackBar('Portfolio document created');
                window.location.reload();
               });
        });
     
    
    
    }
    private pfDocFormatter(part1: PFormPart1,part2: PFormPart2, part3: PFormPart3 ): Promise<any>{
        return new Promise(resolvePf => {
            const todaysDate = _moment().format('YYYY-MM-DD');
            const partOne: PFormPart1 ={};
            const partThree: PFormPart3 = {};
            part3.fileName === '' ? partThree.fileName = `${todaysDate}_SRay_${part3.sray_version}_${part1.clientName}_${part2.portfolio}.xlsx`
            : partThree.fileName = part3.fileName;

            part1.name === '' ? partOne.name = `SRay_Ptf_Screen_${part1.clientName}` :
             partOne.name = part1.name;

            part1.description === '' ? partOne.description = `S-Ray Portfolio Screening for ${part1.clientName}` :
            partOne.description = part1.description;

            partOne.srayApps = part1.srayApps;
            partOne.clientName = part1.clientName;
            partThree.sray_version = part3.sray_version;
            resolvePf({partOne, partThree});
        });
   
   


    }

  

 


}
