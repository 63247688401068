 <!-- REACTIVE FORM EXAMPLE -->
 <form formGroupName="documentFormat" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
 fxFlex="1 0 auto">

 <div class="h2 mb-24">Reactive Form Example</div>



 <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>File Name</mat-label>
        <input matInput formControlName="fileName" >
        <mat-error> File Name is required!</mat-error>
    </mat-form-field>
 </div>
 <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Split by row count</mat-label>
        <input matInput formControlName="splitByRowCount">
        <mat-error> Row count is required!</mat-error>
    </mat-form-field>
 </div>
 <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Separator</mat-label>
        <input matInput formControlName="separator">
        <mat-error> Separator is required!</mat-error>
    </mat-form-field>
 </div>

 <div fxFlex="1 0 auto" fxLayout="row">
    
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Quoting</mat-label>
        <input matInput formControlName="quoting">
        <mat-error> Quoting is required!</mat-error>
    </mat-form-field>
 </div>
    <div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Null representation</mat-label>
        <input matInput formControlName="nullRepresentation">
        <mat-error> Null representation is required!</mat-error>
    </mat-form-field>
</div>
<div fxFlex="1 0 auto" fxLayout="row">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Portfolio</mat-label>
        <input matInput formControlName="sray_version">
        <mat-error> Portfolio is required!</mat-error>
    </mat-form-field>
</div>
 </form>






<!-- / REACTIVE FORM EXAMPLE -->