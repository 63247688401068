<div id="order" class="page-layout carded fullwidth inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg accent"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">

        <!-- HEADER -->
        <div class="header accent"
             fxLayout="row" fxLayoutAlign="space-between center">

            <!-- APP TITLE -->
            <div fxLayout="row" fxLayoutAlign="start center">

                <button mat-icon-button class="mr-16" [routerLink]="'/enterprise'">
                    <mat-icon>arrow_back</mat-icon>
                </button>

                <div fxLayout="column" fxLayoutAlign="start start"
                     [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">
                    <div class="h2">
                        {{document.meta.client_name}} 
                    </div>
                    <div class="subtitle secondary-text">
                        <span>{{document.id}}</span>
                    </div>
                </div>
            </div>
            <!-- / APP TITLE -->
            <button mat-raised-button
                    class="save-product-button"
                    [disabled]="form.invalid || form.pristine"
                     (click)="saveDocument()">
                <span>SAVE DOCUMENT</span>
            </button>
        </div>
        <!-- / HEADER -->

        <!-- CONTENT CARD -->
        <div class="content-card">

            <!-- CONTENT -->
            <div class="content">
                <form  name="form" [formGroup]="form" class="product w-100-p" fxLayout="column" fxFlex>
                    <mat-tab-group fxLayout="column" fxFlex>


                        <mat-tab label="Primary Info">
                           
                                <div class="products tab-content p-24" fusePerfectScrollbar>
                                    <app-primary-info [document] ="document"></app-primary-info>
                                    </div>
                          
                           
                        </mat-tab>
                        <mat-tab label="Product Info">
                          
                                <div class="products tab-content p-24" >
                                    <app-product-info [document] ="document"
                                     [esgGC]="esgGC"
                                     [temperature]="temperature"
                                     [prefFilter]="prefFilter"
                                     [features]="features"
                                     [catholic]="catholic"
                                     [gcESG]="gcESG"
                                     [catholicPreferences]="catholicPreferences"
                                     [identifiers]="identifiers"
                                     [preferences]="preferences"
                                     [supplementalInfo]="supplementalInfo"
                                     [temperatureE]="temperatureE"
                                     [feat]="feat"
                                     [allGcEsg]="allGcEsg"
                                     [allPreferences]="allPreferences"
                                     [allCatholicPreferences]="allCatholicPreferences"
                                     [allTemperatureE]="allTemperatureE"
                                     [allFeatures]="allFeatures"
                                     [allIdentifiers]="allIdentifiers"
                                     [allSupplementalInfo]="allSupplementalInfo">
                                    </app-product-info>
                                    </div>
                           
                           
                        </mat-tab>
                        <mat-tab label="Document Format">
                           
                                <div class="products tab-content p-24" fusePerfectScrollbar>
                                    <app-document-format [document] ="document"></app-document-format>
                                    </div>
                          
                           
                        </mat-tab>
                        <mat-tab label="Document Delivery">
                         
                                <div class="products tab-content p-24" fusePerfectScrollbar>
                                    <app-document-delivery [document] ="document"
                                    [allDataFrequency]="allDataFrequency"
                                    [allDeliveryFrequency]="allDeliveryFrequency"></app-document-delivery>
                                    </div>
                     
                           
                        </mat-tab>
                        <mat-tab label="Doc Builds">
                            <div class="products tab-content p-24" fusePerfectScrollbar>
                                <app-deliveries-table [document] ="document" [productType]="'enterprise'" ></app-deliveries-table>
                                </div>
                    </mat-tab>
                        <mat-tab disabled>
                            <ng-template mat-tab-label>
                                <button  mat-icon-button (click)="deleteDocumentModal()" >
                                    <mat-icon id="delete">cancel</mat-icon>
                                </button>
                            </ng-template>
                        </mat-tab>
                        
                    </mat-tab-group>
            </form>
         

            </div>
            <!-- / CONTENT -->

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->
</div>