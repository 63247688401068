import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatRippleModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';


import { KavigationComponent } from './navigation.component';
import { NavHorizontalItemComponent } from './horizontal/item/item.component';
import { NavHorizontalCollapsableComponent } from './horizontal/collapsable/collapsable.component';

@NgModule({
    imports     : [
        CommonModule,
        RouterModule,

        MatIconModule,
        MatRippleModule,


    ],
    exports     : [
        KavigationComponent
    ],
    declarations: [
        KavigationComponent,
        NavHorizontalItemComponent,
        NavHorizontalCollapsableComponent
    ]
})
export class KavigationModule
{
}
