/* eslint-disable */
import {PortfolioDocument} from './real-doc';
export const testPortfolioDocument: PortfolioDocument = {
id: 'test',
name: 'test',
description: 'Test portfolio document',
meta: {
    benchmark: 'something:260',
    client_name: 'Testty',
    db_api: 'true',
    delivery_frequency: 'monthly',
    delivery_start_date: '11/11/2019',
    dev_sray_service: 'pftscreen',
    file_name: 'fnrjdgbfgdvfgjhb.xslx',
    id_field: 'vbjfdv',
    new_pf: true,
    portfolio: 'test-portoflio',
    portfolio_label: 'test portfolio 37846',
    sray: {
        version_string: '260',
                app_versions: {
                    PreferencesFilter: [
                        3,
                        0,
                        0,
                        0
                    ],
                    temperature: [
                        1,
                        1,
                        0,
                        0
                    ]
                }
    },
    temperature_version: '100',
            template: 'DefaultScreening',
            with_catholic: 'True',
            with_features: 'True',
            with_temperature: 'True'
        },
        tags: [
            'docbuilder'
        ],
        author: null,
        active: true,
        date_created: '2020-08-18T23:59:17.167000',
        date_modified: '2020-08-19T00:37:23.810000'

};

