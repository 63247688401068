import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-email-delivery',
  templateUrl: './email-delivery.component.html',
  styleUrls: ['./email-delivery.component.scss']
})
export class EmailDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
