import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { SnackbarService } from 'app/main/shared/snackbar/snackbar.service';
import { map, tap } from 'lodash';
import { Observable } from 'rxjs';
import { mergeMap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LoginService {
    authToken: string;

    constructor(private http: HttpClient, private router: Router,
        private snackbar: SnackbarService) { }

    login(username: string, password: string): any {
        const data = {
            username,
            password
        };
        return this.http.post('https://srayapi.arabesque.com/api/token', data).subscribe(
            (response: any) => {
                this.authToken = response.access_token;
                sessionStorage.setItem('token', this.authToken);
                if (this.authToken) {
                    this.snackbar.openSnackBar('Login Successful');
                    this.router.navigate(['enterprise']);
                }
               
            }
        );
      
    }
}
