import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { arabesqueAnimations } from '@kav/animations';
import { Deliveries } from 'app/fake-db/deliveries';
import { NewDocRegDocument, PortfolioDocument } from 'app/fake-db/real-doc';
import { DeliveriesService } from 'app/main/enterprise/deliveries/deliveries.service';

@Component({
  selector: 'app-doc-builds',
  templateUrl: './doc-builds.component.html',
  styleUrls: ['./doc-builds.component.scss'],
  animations: arabesqueAnimations,
})
export class DocBuildsComponent implements OnInit {
 @Input() document: NewDocRegDocument | PortfolioDocument;   
    docBuilds: Deliveries;
  constructor(private docBuildService: DeliveriesService) { }

  ngOnInit(): void {
    //   this.docBuildService.getDocBuild(this.document.id).subscribe(data => this.docBuilds = data.reverse());
  }
  

}
