import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ftp-delivery',
  templateUrl: './ftp-delivery.component.html',
  styleUrls: ['./ftp-delivery.component.scss']
})
export class FtpDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
