/* eslint-disable */
import {DataField} from './data-fields';
export const allPreferencesDataFields = {
    pf_ade_flag: [ {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_ade_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_ade_reason'
        }
    } as DataField
 ],
    pf_alc_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_alc_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_alc_reason'
        }
    } as DataField ],
    pf_def_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_def_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_def_reason'
        }
    } as DataField],
    pf_dru_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_dru_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_dru_reason'
        }
    } as DataField, ],
    pf_fir_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_fir_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_fir_reason'
        }
    } as DataField ],
    pf_fos_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_fos_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_fos_reason'
        }
    } as DataField, ],
    pf_gam_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_gam_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_gam_reason'
        }
    } as DataField,
 ],
    pf_gmo_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_gmo_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_gmo_reason'
        }
    } as DataField, ],
    pf_nuc_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_nuc_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_nuc_reason'
        }
    } as DataField, ],
    pf_por_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_por_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_por_reason'
        }
    } as DataField, ],
    pf_ste_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_ste_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_ste_reason'
        }
    } as DataField, ],
    pf_coa_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_coa_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_coa_reason'
        }
    } as DataField
 ],
    pf_tob_flag: [{
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_tob_flag'
        }
    } as DataField,
    {
        field: {
            app_name: 'PreferencesFilterFlag_5_inv',
            app_version: [2, 6, 1, 1],
            name: 'pf_tob_reason'
        }
    } as DataField ],
    pf_con_wea_inv: {
        field: {
            app_name: 'ControversialWeapons',
            app_version: [2, 6, 1, 1],
            name: 'con_wea_inv'
        }
    } as DataField

 
};
