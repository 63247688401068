import { NgModule } from '@angular/core';
import { BrowserModule, HammerModule } from '@angular/platform-browser';

import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import {MatTabsModule} from '@angular/material/tabs';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';



import { KavModule } from '@kav/kav.module';
import { KavSharedModule } from '@kav/shared.module';
import { FuseProgressBarModule } from '@kav/components';

import { appConfig } from 'app/app-config';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatTableModule} from '@angular/material/table';
import { EnterpriseComponent } from './main/enterprise/enterprise.component';
import { PortfolioComponent } from './main/portfolio/portfolio.component';
import { ETableComponent } from './main/enterprise/e-table/e-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormWizardComponent } from './main/enterprise/form-wizard/form-wizard.component';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { ModalComponent } from './main/enterprise/modal/modal.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MatChipsModule } from '@angular/material/chips';
import { MatRippleModule, NativeDateModule } from '@angular/material/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTooltipModule} from '@angular/material/tooltip';

import { DocumentComponent } from './main/enterprise/document/document.component';
import { DocumentService } from './main/enterprise/document/document.service';
import { DocumentsService } from './services/documents.service';
import { PrimaryInfoComponent } from './main/enterprise/document/primary-info/primary-info.component';
import { ProductInfoComponent } from './main/enterprise/document/product-info/product-info.component';
import { DocumentFormatComponent } from './main/enterprise/document/document-format/document-format.component';
import { DocumentDeliveryComponent } from './main/enterprise/document/document-delivery/document-delivery.component';
import { DispatcherTableComponent } from 'app/main/shared/dispatch/dispatcher-table/dispatcher-table.component';
import { DispatcherTableService } from 'app/main/shared/dispatch/dispatcher-table/dispatcher-table.service';
import { DispatchModalComponent } from 'app/main/shared/dispatch/dispatch-modal/dispatch-modal.component';
import { DispatchFormWizardComponent } from 'app/main/shared/dispatch/dispatch-form-wizard/dispatch-form-wizard.component';
import { DeliveriesTableComponent } from './main/enterprise/deliveries/deliveries-table/deliveries-table.component';
import {DeliveriesService} from 'app/main/enterprise/deliveries/deliveries.service';
import {DeliveryComponent} from 'app/main/enterprise/deliveries/delivery/delivery.component';
import {EmailDeliveryComponent} from 'app/main/enterprise/deliveries/delivery/email-delivery/email-delivery.component';
import {FtpDeliveryComponent} from 'app/main/enterprise/deliveries/delivery/ftp-delivery/ftp-delivery.component';
import {SftpDeliveryComponent} from 'app/main/enterprise/deliveries/delivery/sftp-delivery/sftp-delivery.component';
import {DeliveryService} from 'app/main/enterprise/deliveries/delivery/delivery.service';
import { PortfolioDeliveriesComponent } from './main/portfolio/portfolio-deliveries/portfolio-deliveries.component';
import { PortfolioDocumentComponent } from './main/portfolio/portfolio-document/portfolio-document.component';
import {PortfoliosService} from 'app/main/portfolio/portfolios.service';
import { DispartchPortfolioModalComponent } from './main/portfolio/dispatch-portfolio/dispartch-portfolio-modal/dispartch-portfolio-modal.component';
import { DispatchPortfolioWizardComponent } from './main/portfolio/dispatch-portfolio/dispatch-portfolio-wizard/dispatch-portfolio-wizard.component';
import { FilterDataService } from './services/filter-data.service';
import { PortfolioWizardComponent } from './main/portfolio/portfolio-wizard/portfolio-wizard.component';
import { PortfolioModalComponent } from './main/portfolio/portfolio-modal/portfolio-modal.component';
import { CreatePortfolioDocumentService } from './services/create-portfolio-document.service';
import {PortfolioDocumentService} from 'app/main/portfolio/portfolio-document/portfolio-document.service';
import { PortfolioDocumentPrimaryComponent } from './main/portfolio/portfolio-document/portfolio-document-primary/portfolio-document-primary.component';
import { PortfolioDocumentInfoComponent } from './main/portfolio/portfolio-document/portfolio-document-info/portfolio-document-info.component';
import { PortfolioDocumentDeliveryComponent } from './main/portfolio/portfolio-document/portfolio-document-delivery/portfolio-document-delivery.component';
import {DeleteDocumentService} from 'app/services/delete-document.service';
import { DeleteDocumentModalComponent } from 'app/main/shared/delete-document-modal/delete-document-modal.component';
import { LoginComponent } from './main/auth/login/login.component';
import {MatCardModule} from '@angular/material/card';
import {LoginService} from 'app/services/login.service';
import {EditPortfolioDocumentService} from 'app/services/edit-portfolio-document.service';
import { DocBuildsComponent } from './main/shared/doc-builds/doc-builds.component';
import { UrlPipe } from './main/shared/pipes/url.pipe';
import { FilenamePipe } from './main/shared/pipes/filename.pipe';
import { SnackbarService } from './main/shared/snackbar/snackbar.service';
const appRoutes: Routes = [
    {
        path: 'login',
        component: LoginComponent
    },

    {
        path      : 'enterprise',
        component: EnterpriseComponent,
        resolve: {
            data: DocumentsService
        }
    },
    {
        path: 'enterprise/document/:id',
        component: DocumentComponent,
        resolve: {
            data: DocumentService
        }
    },
    {
        path: 'enterprise/delivery/:id',
        component: DeliveryComponent,
        resolve: {
            data: DeliveryService
        }
    },
    {
        path      : 'portfolio',
        component: PortfolioComponent,
        resolve: {
          data: PortfoliosService
        }
    },
    {
        path      : 'portfolio/document/:id',
        component: PortfolioDocumentComponent,
        resolve: {
            data: PortfolioDocumentService
        }
    },
    {
        path: 'portfolio/delivery/:id',
        component: DeliveryComponent,
        resolve: {
            data: DeliveryService
        }
    },
   
    {
        path      : '**',
        redirectTo: 'login'
    }
    
];

@NgModule({
    declarations: [
        AppComponent,
        EnterpriseComponent,
        PortfolioComponent,
        ETableComponent,
        FormWizardComponent,
        ModalComponent,
        DocumentComponent,
        PrimaryInfoComponent,
        ProductInfoComponent,
        DocumentFormatComponent,
        DocumentDeliveryComponent,
        DispatcherTableComponent,
        DispatchModalComponent,
        DispatchFormWizardComponent,
        DeliveriesTableComponent,
        DeliveryComponent,
        EmailDeliveryComponent,
        FtpDeliveryComponent,
        SftpDeliveryComponent,
        PortfolioDeliveriesComponent,
        PortfolioDocumentComponent,
        DispartchPortfolioModalComponent,
        DispatchPortfolioWizardComponent,
        PortfolioWizardComponent,
        PortfolioModalComponent,
        PortfolioDocumentPrimaryComponent,
        PortfolioDocumentInfoComponent,
        PortfolioDocumentDeliveryComponent,
        DeleteDocumentModalComponent,
        LoginComponent,
        DocBuildsComponent,
        UrlPipe,
        FilenamePipe

    ],
    imports     : [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
     
        HammerModule,
        RouterModule.forRoot(appRoutes, { relativeLinkResolution: 'legacy' }),


        // Material moment date module
        MatMomentDateModule,

        // Material
        MatButtonModule,
        MatIconModule,
        MatTabsModule,
       
        MatPaginatorModule,
        MatFormFieldModule,
        MatSelectModule,
        MatStepperModule,
        MatDialogModule,
        MatChipsModule,
        MatExpansionModule,
        MatInputModule,
        MatRippleModule,
        MatSnackBarModule,
        MatSortModule,
        MatCardModule,
        MatTooltipModule,
        // Fuse modules
        KavModule.forRoot(appConfig),
        FuseProgressBarModule,
        KavSharedModule,
        MatTableModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatCheckboxModule,
        MatButtonToggleModule,
        // App modules
        LayoutModule
    ],
    providers: [DocumentService, DispatcherTableService, DeliveriesService, DeliveryService, FilterDataService,
         CreatePortfolioDocumentService, PortfolioDocumentService, DeleteDocumentService, LoginService,
         EditPortfolioDocumentService, SnackbarService],
    bootstrap   : [
        AppComponent
    ],
    exports: [UrlPipe, FilenamePipe]
})
export class AppModule
{
}
