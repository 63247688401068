import { Kavigation } from '@kav/types';

export const demoNav: Kavigation[] = [
    {
        id: 'enterprise',
        title: 'Enterprise',
        type: 'item',
        icon: 'apps',
        url: '/enterprise'
    },
    {
        id: 'portfolio',
        title: 'Portfolio',
        type: 'item',
        icon: 'book',
        url: '/portfolio'
    }
];
