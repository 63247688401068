 /* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import {authToken} from 'app/fake-db/real-doc';
import {Deliveries} from 'app/fake-db/deliveries';
@Injectable({
  providedIn: 'root'
})
export class DeliveriesService {

    routeParams: any;
    public deliveries: Deliveries[] = undefined;
    onDeliveriesChanged: BehaviorSubject<any>;
    authToken: string = sessionStorage.getItem('token');
  constructor(private http: HttpClient) {
    this.onDeliveriesChanged = new BehaviorSubject({});
   }
  

  

    getDocBuild(docId: string): Promise<any> {
        const authHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        });
        return new Promise((resolve, reject) => {
            this.http.get(`http://127.0.0.1:8080/doc-registry/api/v1/docbuild/?document=${docId}`,
            {headers: authHeaders})
                .subscribe((response: any) => {
                    console.log(response);
                    this.deliveries = response;
                    this.onDeliveriesChanged.next(this.deliveries);
                    resolve(response);
                }, reject);
        });
    }
}
