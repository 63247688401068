import { Component, Host, Input, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PortfolioDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-portfolio-document-primary',
  templateUrl: './portfolio-document-primary.component.html',
  styleUrls: ['./portfolio-document-primary.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class PortfolioDocumentPrimaryComponent implements OnInit {
@Input() portfolioDoc: PortfolioDocument;
primaryPortfolioInfo: any;
form: FormGroup;
fgd: FormGroupDirective;


  constructor(private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective) { 
  
  }

  
  ngOnInit(): void {
    this.form = this.parentFor.form;
   
       // Reactive Form
    this.form.addControl('primaryPortfolioInfo', this.formBuilder.group(
        {
        
            name: [this.portfolioDoc.name, Validators.required],
            description: [this.portfolioDoc.description, Validators.required],
            clientName: [this.portfolioDoc.meta.client_name, Validators.required],
        }
    ));

  }
}
