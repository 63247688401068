import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { arabesqueAnimations } from '@kav/animations';
import { LoginService } from 'app/services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: arabesqueAnimations,
  encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit {

    loginForm: FormGroup;

 
    constructor(

        private formBuilder: FormBuilder,
        private loginService: LoginService,
    )
    {
      
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void
    {
        this.loginForm = this.formBuilder.group({
            email   : ['', [Validators.required, Validators.email]],
            password: ['', Validators.required]
        });
    }
    login(): void {
        this.loginService.login(this.loginForm.value.email, this.loginForm.value.password);
    }

}
