import { NgModule } from '@angular/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { KavSharedModule } from '@kav/shared.module';

import { ContentModule } from 'app/layout/components/content/content.module';
import { NavbarModule } from 'app/layout/components/navbar/navbar.module';

import { HorizontalLayout1Component } from 'app/layout/horizontal/layout-1/layout-1.component';

@NgModule({
    declarations: [
        HorizontalLayout1Component
    ],
    imports     : [
        MatSidenavModule,

        KavSharedModule,

        ContentModule,
        NavbarModule,

    ],
    exports     : [
        HorizontalLayout1Component
    ]
})
export class HorizontalLayout1Module
{
}
