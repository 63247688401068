 /* eslint-disable */
import { Injectable } from '@angular/core';
import * as _moment from 'moment';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { authToken, PFormPart1, PFormPart2, PFormPart3, PFormPart4, PortfolioDocument } from 'app/fake-db/real-doc';
import { SrayApps } from 'app/fake-db/app-versions';
@Injectable({
  providedIn: 'root'
})
export class CreatePortfolioDocumentService {
    authToken: string = sessionStorage.getItem('token');
constructor(private http: HttpClient) { }


createPortfolioDocumentFormat(part1: PFormPart1, part2: PFormPart2, part3: PFormPart3, part4: PFormPart4): Observable<PortfolioDocument> {
    const newPortfolioDocument: PortfolioDocument = {
        name: part1.name,
        description: part1.description,
        meta: {
            client_name: part1.clientName,
            benchmark: part2.benchmark,
            db_api: 'true',
            id_field: part2.identifiers,
            new_pf: true,
            dev_sray_service: 'pftscreen',
            sray: {
                version_string: part3.sray_version,
                app_versions: this.srayAppsFormat(part1.srayApps)
            },
            file_name: part3.fileName,
            delivery_frequency: part4.deliveryFrequency,
            delivery_start_date: part4.deliveryStartDate,
            data_start_date: part4.dataStartDate,
            data_end_date: part4.dataEndDate,
            portfolio: part2.portfolio,
            portfolio_label: part2.portfolioLabel,
            sray_version: part3.sray_version,
            temperature_version: '100',
            template: 'DefaultScreening',
            with_catholic: `${part2.snapshots.includes('with_catholic')}`.toString(),
            with_temperature: `${part2.snapshots.includes('with_temperature')}`.toString(),
            with_features: `${part2.snapshots.includes('with_features')}`.toString(),
            
        },
        tags: ['docbuilder']
    };
    console.log(newPortfolioDocument);
   return this.docApi(newPortfolioDocument);
}
private docApi(portfolio: PortfolioDocument): Observable<any> {
    const httpOptions = { 
        headers: new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        })
    };
    return this.http.post('http://127.0.0.1:8080/doc-registry/api/v1/document/', portfolio, httpOptions)
    .pipe(
        catchError(this.handleError)
    );
   } 
   private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }


private srayAppsFormat(formApps: SrayApps[]): any{
  
    return Object.fromEntries(
        formApps.map(e => [e.appName, e.version])
       );
    
   }

  
      

}
