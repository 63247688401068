 /* eslint-disable */
import { Component, Host, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, ControlContainer } from '@angular/forms';
import { NewDocRegDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-document-format',
  templateUrl: './document-format.component.html',
  styleUrls: ['./document-format.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class DocumentFormatComponent implements OnInit {

    form: FormGroup;
    @Input() document: NewDocRegDocument;
  constructor( private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective) { }

  ngOnInit(): void {
      this.form = this.parentFor.form;
     
       // Reactive Form
      this.form.addControl('documentFormat', this.formBuilder.group({
     
        fileName: [this.document.meta.file_name],
        splitByRowCount: [this.document.meta.split_by_rowcount],
        separator: [this.document.meta.separator],
        quoting: ['minimal'],
        nullRepresentation: [''],
        sray_version: ['sray:260']
    }));

  }

}
