<div id="portfolio" class="page-layout carded fullwidth tabbed inner-scroll">

    <!-- TOP BACKGROUND -->
    <div class="top-bg primary"></div>
    <!-- / TOP BACKGROUND -->

    <!-- CENTER -->
    <div class="center">
      
          <div class="header accent"
          fxLayout="column" fxLayoutAlign="center center"
          fxLayout.gt-xs="row" fxLayoutAlign.gt-xs="space-between center">

         <!-- APP TITLE -->
         <div class="logo mb-24 mb-sm-0"
              fxLayout="row" fxLayoutAlign="start center">
             <mat-icon class="logo-icon s-32 mr-16" [@animate]="{value:'*',params:{delay:'50ms',scale:'0.2'}}">
                 book
             </mat-icon>
             <span class="logo-text h1" [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}">Portfolio Page</span>
         </div>
         <!-- / APP TITLE -->

         <!-- SEARCH -->
         <div class="search-wrapper  ml-sm-32">
            <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                <mat-icon>search</mat-icon>
                <input (keyup)="applyFilter($event)" type="search" #filter placeholder="Search by name">
            </div>
        </div>
        <mat-button-toggle-group #group="matButtonToggleGroup">
            <mat-button-toggle (click)="createNewDocuments()" class="document-modal" value="left" aria-label="Text align left">
              <mat-icon color="primary" >build_filled</mat-icon>
            </mat-button-toggle>
            <mat-button-toggle (click)="dispatchNewDocuments()" value="right" aria-label="Text align center">
              <mat-icon class="secondary-button" >send</mat-icon>
            </mat-button-toggle>
        
        
          </mat-button-toggle-group>
<!-- / ADD BUTTON -->

     </div>
     <!-- / HEADER -->
        
       
        <!-- CONTENT CARD -->
        <div class="content-card">



                   

                         
                            <app-dispatcher-table  [tableData]="tableData"  [route]="route" [productType]="'portfolio'"
                            ></app-dispatcher-table>
                            <!-- / PUT YOUR TAB CONTENT HERE -->
                     

               

 

    

        </div>
        <!-- / CONTENT CARD -->

    </div>
    <!-- / CENTER -->

</div>
