import { AfterContentChecked, AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ControlContainer, FormGroup, FormGroupDirective } from '@angular/forms';
import { arabesqueAnimations } from '@kav/animations';
import { DataField } from 'app/fake-db/data-fields';
import { DocumentColumns, identifiers, gcEsg, preferences, dataFrequency, deliveryFrequency, productType, supplementalInfo, temperatureE, catholicPreferences, features } from 'app/fake-db/form-helpers';
import { DocRegDocument, NewDocRegDocument } from 'app/fake-db/real-doc';
import { DocumentService} from './document.service';
import {EditDocumentService} from 'app/services/edit-document.service';
import { Router } from '@angular/router';
import { DeleteDocumentService } from 'app/services/delete-document.service';
import { MatDialog } from '@angular/material/dialog';
import { DeleteDocumentModalComponent } from 'app/main/shared/delete-document-modal/delete-document-modal.component';
import { DeliveriesService } from '../deliveries/deliveries.service';
import { Deliveries } from 'app/fake-db/deliveries';
import { SnackbarService } from 'app/main/shared/snackbar/snackbar.service';
@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
  animations: arabesqueAnimations
})
export class DocumentComponent implements OnInit, AfterContentChecked, AfterViewInit {
document: NewDocRegDocument;
columns: string[];
esgGC = false;
temperature = false;
prefFilter = false;
features = false;
catholic = false;
gcESG: string[] = [];
catholicPreferences: string[] = [];
identifiers: string[] = [];
preferences: string[] = [];
supplementalInfo: string[] = [];
temperatureE: string[] = [];
feat: string[] = [];
docBuilds: Deliveries[];
allIdentifiers: DocumentColumns[] = identifiers;
allGcEsg: DocumentColumns[] = gcEsg;
allPreferences: DocumentColumns[] = preferences;
allDataFrequency: DocumentColumns[] = dataFrequency;
allDeliveryFrequency: DocumentColumns[] = deliveryFrequency;
allProductType: DocumentColumns[] = productType;
allSupplementalInfo: DocumentColumns[] = supplementalInfo;
allTemperatureE: DocumentColumns[] = temperatureE;
allCatholicPreferences: DocumentColumns[] = catholicPreferences;
allFeatures: DocumentColumns[] = features;

  
form = new FormGroup({});
  constructor(private service: DocumentService, private router: Router,
              private ref: ChangeDetectorRef,
              private editService: EditDocumentService,
              private deleteDoc: DeleteDocumentService,
              private snackbar: SnackbarService,
              
              public dialog: MatDialog) {
      this.document = this.service.document;
    
   }


  ngOnInit(): void {
      if (this.document.meta.data_fields.length > 0) {
        // eslint-disable-next-line @typescript-eslint/prefer-for-of
        for (let i = 0; i < this.document.meta.data_fields.length; i++) {
            const element = this.document.meta.data_fields[i];
            switch (element.field.app_name) {
              case 'CatholicFilter':
              this.catholicPreferences.push(element.field.name);
              this.catholic = true;
              break;
              case 'assetinfo_security_id':
              this.identifiers.push(element.field.name);
              break;
              case 'assetinfo_entity':
              this.identifiers.push(element.field.name);
              break;
              case 'assetinfo_name':
              this.supplementalInfo.push(element.field.name);    
              break;
              case 'assetinfo_activity':
              this.supplementalInfo.push(element.field.name);    
              break;
              case 'assetinfo_dom_country':
              this.supplementalInfo.push(element.field.name);
              break;
              case 'assetinfo_exch_country':
              this.supplementalInfo.push(element.field.name);
              break;
              case 'assetinfo_exchange':
              this.identifiers.push(element.field.name);
              break;
              case 'ControversialWeapons':
              this.preferences.push(element.field.name);
              break;
              case 'GC':
              this.gcESG.push(element.field.name);
              this.esgGC = true;    
              break;
              case 'ESG':
              this.gcESG.push(element.field.name);
              this.esgGC = true;
              break;
              case 'FeatureTotal':
              this.feat.push(element.field.name);    
              break;
              case 'PreferencesFilterFlag_5_inv':
              this.preferences.push(element.field.name);
              break;
              case 'temperature':
              this.temperatureE.push(element.field.name);    
              break;
              case 'temperature_emissions':
              this.temperatureE.push(element.field.name);
              break;
              case 'temperature_financials':
              this.temperatureE.push(element.field.name);    
              break;
              case 'temperature_sbti':
              this.temperatureE.push(element.field.name);
              break;
              
            }
            
            
        }
      }

  }
  ngAfterContentChecked(): void {
    this.ref.detectChanges();
  }
  ngAfterViewInit(): void {
   
  }
  saveDocument(): void {
   
    const regex = /(reason)/g;
    const array = this.form.value.productInfo.preferences;
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < array.length; i++) {
        const element = array[i];
        if(element.match(regex)) {
            array.splice(i,1);
        }
    }
    this.editService.editDocument(this.document, this.form.value).subscribe(data => {
        this.snackbar.openSnackBar(`Edited ${this.document.id} successfully`);
        this.router.navigate(['enterprise']);
    });
  }
  deleteDocumentModal(): void {
   this.dialog.open(DeleteDocumentModalComponent, {
        data: this.document
      });
}

}
