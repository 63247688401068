 /* eslint-disable */
import {DataField} from './data-fields';
export const allCatholicDataFields = {
    cath_flag: {
        field: {
            app_name: 'CatholicFilter',
            app_version: [1, 0, 0, 0],
            name: 'cath_flag'
        }
    } as DataField,
    gc_en_rank: {
        field: {
            app_name: 'CatholicFilter',
            app_version: [1, 0, 0, 0],
            name: 'gc_en_rank'
        }
    } as DataField,
    gc_hr_rank: {
        field: {
            app_name: 'CatholicFilter',
            app_version: [1, 0, 0, 0],
            name: 'gc_hr_rank'
        }
    } as DataField,
    gc_lr_rank: {
        field: {
            app_name: 'CatholicFilter',
            app_version: [1, 0, 0, 0],
            name: 'gc_lr_rank'
        }
    } as DataField,
    feat_hc_div_rank: {
        field: {
            app_name: 'CatholicFilter',
            app_version: [1, 0, 0, 0],
            name: 'feat_hc_div_rank'
        }
    } as DataField,
    feat_sr_com_rank: {
        field: {
            app_name: 'CatholicFilter',
            app_version: [1, 0, 0, 0],
            name: 'feat_sr_com_rank'
        }
    } as DataField
};
