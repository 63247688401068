 /* eslint-disable */
import { HttpHeaders, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExposedPortfolioFormObject, PortfolioDocument } from 'app/fake-db/real-doc';
import { resolve } from 'dns';
import { Observable, from, throwError } from 'rxjs';
import { mergeMap, retryWhen, delay, take, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditPortfolioDocumentService {
    portfolioDocument: PortfolioDocument;
    authToken: string = sessionStorage.getItem('token');
  constructor(private http: HttpClient) { }
  editPortfolioDocument(portfolio: PortfolioDocument, form: ExposedPortfolioFormObject): Observable<any> {
    const httpOptions = { 
        headers: new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        })
    };
    return from(this.combinePortfolioDocObjects(portfolio, form))
   .pipe(
       mergeMap((data: PortfolioDocument) => this.http.patch(`http://127.0.0.1:8080/doc-registry/api/v1/document/${portfolio.id}`, data, httpOptions)),
       retryWhen(errors => errors.pipe(delay(1000), take(2))),
      catchError(this.handleError)
   );
  
    
   } 
   private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }
  private appendPortfolioPrimaryInfo(portfolio: PortfolioDocument, form: ExposedPortfolioFormObject): Promise<PortfolioDocument> {
   
    return new Promise((_resolve) => {
        const primaryPortfolioInfo = form.primaryPortfolioInfo;
        if (primaryPortfolioInfo.name) {
            
            portfolio.name = form.primaryPortfolioInfo.name;
        }
        if (primaryPortfolioInfo.description) {
    
            portfolio.description = form.primaryPortfolioInfo.description;
        }
        if (primaryPortfolioInfo.clientName) {

            portfolio.meta.client_name = primaryPortfolioInfo.clientName;
        }
        _resolve(portfolio);
    });
  }
  private appendPortfolioDocumentInfo(portfolio: PortfolioDocument, form: ExposedPortfolioFormObject): Promise<PortfolioDocument> {
      return new Promise((_resolve) => {
          const portfolioInfo = form.portfolioInfo;

          if (portfolioInfo.benchmark) {
            
            portfolio.meta.benchmark = form.portfolioInfo.benchmark;
        }
          if (portfolioInfo.db_api) {
    
            portfolio.meta.db_api = form.portfolioInfo.db_api;
        }
          if (portfolioInfo.file_name) {

            portfolio.meta.file_name = form.portfolioInfo.file_name;
        }
          if (portfolioInfo.id_field) {

            portfolio.meta.id_field = form.portfolioInfo.id_field;
        }
          if (portfolioInfo.portfolio) {

            portfolio.meta.portfolio = form.portfolioInfo.portfolio;
        }
          if (portfolioInfo.portfolio_label) {

            portfolio.meta.portfolio_label = form.portfolioInfo.portfolio_label;
        }
          if (portfolioInfo.sray_version) {

            portfolio.meta.sray_version = form.portfolioInfo.sray_version;
        }
          _resolve(portfolio);
      });
  }
  private appendPortfolioDeliveryInfo(portfolio: PortfolioDocument, form: ExposedPortfolioFormObject): Promise<PortfolioDocument> {
      return new Promise((_resolve) => {
          const portfolioDelivery = form.portfolioDelivery;
          if (portfolioDelivery.app_versions) {
            
            portfolio.meta.sray.app_versions = form.portfolioDelivery.app_versions;
        }
          if (portfolioDelivery.version_string) {
    
            portfolio.meta.sray.version_string = form.portfolioDelivery.version_string;
        }
          if (portfolioDelivery.temperature_version) {

            portfolio.meta.temperature_version = form.portfolioDelivery.temperature_version;
        }
          if (portfolioDelivery.template) {

            portfolio.meta.template = form.portfolioDelivery.template;
        }
          if (portfolioDelivery.with_catholic) {

            portfolio.meta.with_catholic = form.portfolioDelivery.with_catholic;
        }
          if (portfolioDelivery.with_features) {

            portfolio.meta.with_features = form.portfolioDelivery.with_features;
        }
          if (portfolioDelivery.with_temperature) {

            portfolio.meta.with_temperature = form.portfolioDelivery.with_temperature;
        }
          if (portfolioDelivery.delivery_frequency) {

            portfolio.meta.delivery_frequency = form.portfolioDelivery.delivery_frequency;
        }
          if (portfolioDelivery.delivery_start_date) {

            portfolio.meta.delivery_start_date = form.portfolioDelivery.delivery_start_date;
        }
          _resolve(portfolio);

      });
  }
  private deleteFields(portfolio: PortfolioDocument, form: ExposedPortfolioFormObject): Promise<PortfolioDocument> {
    return new Promise(_resolve => {
        delete portfolio['active'];
        delete portfolio.author;
        delete portfolio.date_created;
        delete portfolio.date_modified;
        _resolve(portfolio);

    });
  }
 private  async combinePortfolioDocObjects(portfolio: PortfolioDocument, form: ExposedPortfolioFormObject): Promise<PortfolioDocument> {
      const primaryInfo = await this.appendPortfolioPrimaryInfo(portfolio, form);
      const portfolioDoc = await this.appendPortfolioDocumentInfo(primaryInfo, form);
      const delivery = await this.appendPortfolioDeliveryInfo(portfolioDoc, form);
      return await this.deleteFields(delivery, form);
  }
}
