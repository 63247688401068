import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filename'
})
export class FilenamePipe implements PipeTransform {

    transform(uri: string): string {
        const realUri = uri;
        const stringArray = realUri.split('/');
        let filename = '';
        stringArray.forEach(e => {
            if(e.endsWith('.csv')) {
                filename = e;
            }
        });
        return filename;
       }

}
