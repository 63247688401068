 /* eslint-disable */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Deliveries } from 'app/fake-db/deliveries';
import { authToken } from 'app/fake-db/real-doc';
import { DocumentsService } from 'app/services/documents.service';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DeliveryService {

    routeParams: any;
    delivery: Deliveries;
    onDeliveryChanged: BehaviorSubject<any>;
    authToken: string = authToken;
    constructor( private router: Router, private active: ActivatedRoute, private docs: DocumentsService, private http: HttpClient) {
    this.onDeliveryChanged = new BehaviorSubject({});
   }
 
   resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any
    {
        this.routeParams = route.params;

        return new Promise((resolve, reject) => {

            Promise.all([
                this.getDelivery(route.params.id)
            ]).then(
                () => {
                    resolve();
                },
                reject
            );
        });
    }
    getDelivery(id: string): Promise<any> {
        const authHeaders = new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        });

        return new Promise((resolve, reject) => {
            this.http.get(`http://127.0.0.1:8080/doc-registry/api/v1/docbuild/${id}`,
            {headers: authHeaders})
                .subscribe((response: any) => {
                    this.delivery = response;
                    this.onDeliveryChanged.next(this.delivery);
                    resolve(response);
                }, reject);
        });
    }
}
