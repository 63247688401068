import { AfterViewChecked, AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { arabesqueAnimations } from '@kav/animations';
import { NewDocRegDocument } from 'app/fake-db/real-doc';
import { ModalComponent } from './modal/modal.component';
import {DispatcherTableService} from 'app/main/shared/dispatch/dispatcher-table/dispatcher-table.service';
import { DispatchModalComponent } from 'app/main/shared/dispatch/dispatch-modal/dispatch-modal.component';
import { DeliveriesService } from './deliveries/deliveries.service';
import { DocumentsService } from 'app/services/documents.service';
import { fromEvent } from 'rxjs';
import { FilterDataService} from 'app/services/filter-data.service';

@Component({
  selector: 'app-enterprise',
  templateUrl: './enterprise.component.html',
  styleUrls: ['./enterprise.component.scss'],
  animations: arabesqueAnimations,
  encapsulation: ViewEncapsulation.None
})
export class EnterpriseComponent implements OnInit, AfterViewInit {
@ViewChild('filter', {static: true}) filter: ElementRef;
dispatchTab = false;
documentsTab = true;
deliveriesTab = false;
tableData: any[];
displayedColumns: string[] = ['id', 'meta.client_name', 'name', 'date_created', 'date_modified'];
route = '/enterprise/document';
active: boolean;
filterValue: string;

  constructor(public dialog: MatDialog,
              private dispatch: DispatcherTableService,
              private docs: DocumentsService,
              private filterService: FilterDataService) { }

  ngOnInit(): void {
      this.tableData = this.docs.documents;
  }
  createNewDocuments(): void {
 this.dialog.open(ModalComponent);
  }
  dispatchNewDocuments(): void {
      this.dialog.open(DispatchModalComponent, {
        height: '1000px',
        width: '800px',
      });
  }

  onLinkClick(event): void {
    this.filter.nativeElement.value = '';
    this.filterService.changeTableData(this.filter.nativeElement.value);
    if (event.index === 0) {
        this.dispatchTab = false;
        this.documentsTab = true;
        this.deliveriesTab = false;
      }
    if (event.index === 1) {
        this.filter.nativeElement.value = '';
        this.filterService.changeTableData(this.filter.nativeElement.value);   
        this.dispatchTab = true;
        this.documentsTab = false;
        this.deliveriesTab = false;
      }
    if (event.index === 2) {
        this.filter.nativeElement.value = '';
        this.filterService.changeTableData(this.filter.nativeElement.value);
        this.dispatchTab = false;
        this.documentsTab = false;
        this.deliveriesTab = true;
      }
  }
  ngAfterViewInit(): void {
  }
  applyFilter(event: Event): void {
    this.filterService.changeTableData(this.filter.nativeElement.value);
  }
 

}
