 <!-- REACTIVE FORM EXAMPLE -->
<form formGroupName="productInfo" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
fxFlex="1 0 auto">
<div class="h2 mb-24">Reactive Form Example</div>

<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="100"  class="pl-4" >
        <mat-label>GC ESG</mat-label>
        <mat-select formControlName="gcEsg" multiple >
            <mat-option *ngFor="let ec of allGcEsg" [value]="ec.value">
                {{ec.viewValue}}
              </mat-option>
        </mat-select>
        <mat-error>GC ESG Columns are required!</mat-error>
    </mat-form-field>
</div>
<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4"  >
        <mat-label>Preferences</mat-label>
        <mat-select formControlName="preferences" multiple>
            <mat-option *ngFor="let pref of allPreferences" [value]="pref.value">
                {{pref.viewValue}}
              </mat-option>
        </mat-select>
    </mat-form-field>
</div>

 


 
<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4" >
        <mat-label>Temperature / Emissions</mat-label>
        <mat-select formControlName="temperatureE" multiple>
            <mat-option *ngFor="let temp of allTemperatureE" [value]="temp.value">
                {{temp.viewValue}}
              </mat-option>
        </mat-select>
    </mat-form-field>

</div>

   

<div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Catholic Filter</mat-label>
        <mat-select formControlName="catholicPreferences" multiple>
            <mat-option *ngFor="let cath of allCatholicPreferences" [value]="cath.value">
                {{cath.viewValue}}
              </mat-option>
        </mat-select>
    </mat-form-field>
</div>

 

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4"  >
        <mat-label>Features</mat-label>
        <mat-select formControlName="features" multiple>
            <mat-option *ngFor="let feature of allFeatures" [value]="feature.value">
                {{feature.viewValue}}
              </mat-option>
        </mat-select>
    </mat-form-field>
  </div>



    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
            <mat-label>Identifiers</mat-label>
            <mat-select formControlName="identifiers" multiple >
                <mat-option *ngFor="let detail of allIdentifiers" [value]="detail.value">
                    {{detail.viewValue}}
                  </mat-option>
            </mat-select>
            
            <mat-error>Identifiers are required!</mat-error>
        </mat-form-field>
    </div>

 

    <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
        <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
            <mat-label>Supplemental Information</mat-label>
            <mat-select formControlName="supplementalInfo" multiple >
                <mat-option *ngFor="let supplement of allSupplementalInfo" [value]="supplement.value">
                    {{supplement.viewValue}}
                  </mat-option>
            </mat-select>
           
            <mat-error>Supplemental Information are required!</mat-error>
        </mat-form-field>
    </div>

  


</form>