
  <div class="mat-elevation-z4" [@slideInBottom]>
    <table mat-table [dataSource]="dataSource"   
  fusePerfectScrollbar class="mt-12" matSort>

    <ng-container *ngFor="let col of displayedColumns" [matColumnDef]="col">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> {{ col }} </th>
        <td mat-cell *matCellDef="let element">
            <span [ngSwitch]="col">
                <span class="filename" *ngSwitchCase="'file_name'">
                    {{element.path | filename}}
              </span>
                <span class="path" *ngSwitchCase="'path'">
                    {{ element.path}}
                </span>
                <span *ngSwitchCase="'date_modified'">
                    {{ element.date_modified | date: 'yyyy-MM-dd'}}
                </span>
               
                <span *ngSwitchCase="'file'">
                      <a href="{{element.path | url}}" target="_blank" rel="{{element.path}}">
                        <mat-icon>folder_open</mat-icon>
                    </a>
                </span>
                <span *ngSwitchDefault>
                    {{ element[col] }}
                </span>
            </span>  
           
            </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns;"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"
     class="example-element-row"  ></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[10, 20, 50]" showFirstLastButtons></mat-paginator>

  </div>
  