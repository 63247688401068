/* eslint-disable */
export interface DocumentColumns {
    value: string;
    viewValue: string;
}
export interface GeneratedFileInformation {
    fileName?: string;
    documentName?: string;
    documentDescription?: string;
    fileType?: string;
}
export const identifiers: DocumentColumns[] = [
   { value: 'date', viewValue: 'Date'},
    { value: 'sedol', viewValue: 'Sedol'},
    { value: 'ticker', viewValue: 'Ticker'},
    { value: 'fsymid', viewValue: 'Fsymid'},
    { value: 'isin', viewValue: 'ISIN'},
    { value: 'fsentityid', viewValue: 'FS Entity ID'},
    { value: 'bbg_ticker', viewValue: 'BBG Ticker'},
    { value: 'exchange_iso', viewValue: 'Exchange ISO'},
    { value: 'exchange_mic', viewValue: 'Exchange Mic'}
];
export const supplementalInfo: DocumentColumns[] = [
    { value: 'name', viewValue: 'Company Name'},
    { value: 'dom_country_name', viewValue: 'Domicile Country'},
    { value: 'dom_region', viewValue: 'Domicile Region'},
    { value: 'economic_sector', viewValue: 'Economic Sector'},
    { value: 'industry', viewValue: 'Industry'},
    { value: 'dom_country_iso', viewValue: 'DOM Country ISO'},
    { value: 'exch_region', viewValue: 'Exchange region'},
    { value: 'exch_country_iso', viewValue: 'Exchange Country ISO'}
];
export const gcEsg: DocumentColumns[] = [
    { value: 'gc', viewValue: 'GC Score'},
    { value: 'gc_hr', viewValue: 'GC Human Rights'},
    { value: 'gc_lr', viewValue: 'GC Labour Rights'},
    { value: 'gc_en', viewValue: 'GC Environment'},
    { value: 'gc_ac', viewValue: 'GC Anti-Corruption'},
    { value: 'esg', viewValue: 'ESG Score'},
    { value: 'esg_e', viewValue: 'ESG Environment'},
    { value: 'esg_s', viewValue: 'ESG Social'},
    { value: 'esg_g', viewValue: 'ESG Governance'}
];

export const preferences: DocumentColumns[] = [
    {value: 'pf_ade_flag', viewValue: 'Adult Entertainment'},
    {value: 'pf_alc_flag', viewValue: 'Alcohol'},
    {value: 'pf_con_wea_inv', viewValue: 'Controversial Weapons Involvement'},
    {value: 'pf_def_flag', viewValue: 'Defence'},
    {value: 'pf_dru_flag', viewValue: 'Recreational Drugs'},
    {value: 'pf_fir_flag', viewValue: 'Firearms'},
    {value: 'pf_fos_flag', viewValue: 'Fossil Fuels'},
    {value: 'pf_gam_flag', viewValue: 'Gambling'},
    {value: 'pf_gmo_flag', viewValue: 'GMO'},
    {value: 'pf_nuc_flag', viewValue: 'Nuclear'},
    {value: 'pf_por_flag', viewValue: 'Pork'},
    {value: 'pf_ste_flag', viewValue: 'Stem Cells'},
    {value: 'pf_coa_flag', viewValue: 'Thermal Coal'},
    {value: 'pf_tob_flag', viewValue: 'Tobacco'},
    ];
export const catholicPreferences: DocumentColumns[] = [
    { value: 'cath_flag', viewValue: 'Catholic'},
    { value: 'gc_en_rank', viewValue: 'Environmental Preservation' },
    { value: 'gc_hr_rank', viewValue: 'Human Rights' },
    { value: 'gc_lr_rank', viewValue: 'Labour Rights' },
    { value: 'feat_hc_div_rank', viewValue: 'Diversity' },
    { value: 'feat_sr_com_rank', viewValue: 'Community Relations' }
];    
export const dataFrequency: DocumentColumns[] = [
{ value: 'daily', viewValue: 'Daily'},
{ value: 'weekly', viewValue: 'Weekly'},
{ value: 'fortnightly', viewValue: 'Fortnightly'},
{ value: 'monthly', viewValue: 'Monthly'},
];
export const deliveryFrequency: DocumentColumns[] = [
    { value: 'daily', viewValue: 'Daily'},
    { value: 'weekly', viewValue: 'Weekly'},
    { value: 'fortnightly', viewValue: 'Fortnightly'},
    { value: 'monthly', viewValue: 'Monthly'},
    ];
export const portfolioDeliveryFrequency: DocumentColumns[] = [
        { value: 'daily', viewValue: 'Daily'},
        { value: 'weekly', viewValue: 'Weekly'},
        { value: 'monthly', viewValue: 'Monthly'},
        { value: 'quarterly', viewValue: 'Quarterly'},
        { value: 'annually', viewValue: 'Annually'}
        ];
export const productType: DocumentColumns[] = [
    { value: 'esgGC', viewValue: 'ESG / GC'},
    { value: 'temperature', viewValue: 'Temperature'},
    { value: 'preferences', viewValue: 'Preferences'},
    { value: 'features', viewValue: 'Features'},
    { value: 'catholic', viewValue: 'Catholic'},
];
export const temperatureE: DocumentColumns[] = [
{ value: 'temp_score_near', viewValue: 'Temperature Score (2030)' }, 
{ value: 'temp_score_far', viewValue: 'Temperature Score (2050)' },
{ value: 'temp_trend', viewValue: 'Trend Indicator' },
{ value: 'temp_target', viewValue: 'Target Indicator' },
{ value: 'temp_scope3', viewValue: 'Scope 3 Indicator' },
{ value: 'temp_iea_sector', viewValue: 'IEA Sector' },
{ value: 'temp_em_1', viewValue: 'Scope 1 Emissions' },
{ value: 'temp_em_2', viewValue: 'Scope 2 Emissions' },
{ value: 'temp_em_3', viewValue: 'Scope 3 Emissions' },
{ value: 'temp_em_date', viewValue: 'Emissions Report Date' },
];
export const features: DocumentColumns[] = [
{ value: 'feat_be', viewValue: 'Business Ethics' },
{ value: 'feat_cap_str', viewValue: 'Capital Structure' },
{ value: 'feat_cg_boa', viewValue: 'Corporate Governance' },
{ value: 'feat_cg_tra', viewValue: 'Transparency' },
{ value: 'feat_e_air', viewValue: 'Emissions' },
{ value: 'feat_e_man', viewValue: 'Environmental Management' },
{ value: 'feat_e_oth', viewValue: 'Waste' },
{ value: 'feat_es_bio', viewValue: 'Environmental Stewardship' },
{ value: 'feat_for_acc', viewValue: 'Forensic Accounting' },
{ value: 'feat_hc_com', viewValue: 'Compensation' },
{ value: 'feat_hc_div', viewValue: 'Diversity' },
{ value: 'feat_hc_emp', viewValue: 'Employment Quality' },
{ value: 'feat_hc_hri', viewValue: 'Human Rights' },
{ value: 'feat_hc_lri', viewValue: 'Labour Rights' },
{ value: 'feat_hc_ohs', viewValue: 'Occupational Health & Safety' },
{ value: 'feat_hc_trd', viewValue: 'Training & Development' },
{ value: 'feat_pl_qas', viewValue: 'Product Quality & Safety' },
{ value: 'feat_ru_ene', viewValue: 'Resource Use' },
{ value: 'feat_ru_wat', viewValue: 'Water' },
{ value: 'feat_s_ene', viewValue: 'Environmental Solutions' },
{ value: 'feat_sr_com', viewValue: 'Community Relations' },
{ value: 'feat_sr_pra', viewValue: 'Product Access' }
];

export const deliveryJobTypes: DocumentColumns[] = [
    {value: 'DeliveryEmailJob', viewValue: 'Email'},
    {value: 'DeliveryAWSS3Job', viewValue: 'S3'},
    {value: 'DeliveryFTPJob', viewValue: 'FTP'},
    {value: 'DeliverySFTPJob', viewValue: 'SFTP'}
];

export const documentTypes: DocumentColumns[] = [
    {viewValue: 'Enterprise', value: 'enterprise'},
    {value: 'ptfscreen', viewValue: 'Portfolio'}
];
export const portfolioFeatures: DocumentColumns[] = [
    { value: 'with_temperature', viewValue: 'Temperature'},
    { value: 'with_features', viewValue: 'Features'},
    {value: 'with_catholic', viewValue: 'Catholic'}
];

export const fileTypes: DocumentColumns[] = [
    { value: 'update', viewValue: 'Update'},
    {value: 'historical', viewValue: 'Historical'}
];