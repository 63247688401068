import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sftp-delivery',
  templateUrl: './sftp-delivery.component.html',
  styleUrls: ['./sftp-delivery.component.scss']
})
export class SftpDeliveryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
