 <!-- REACTIVE FORM EXAMPLE -->
 <form formGroupName="primaryPortfolioInfo" class="p-24 mr-24" fxLayout="column" fxLayoutAlign="start"
 fxFlex="1 0 auto">

 <div class="h2 mb-24">Reactive Form Example</div>



 <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
     
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>Document name</mat-label>
        <input matInput formControlName="name" >
        <mat-error>Document  name is required!</mat-error>
    </mat-form-field>
    <mat-form-field appearance="outline" fxFlex="50" class="pl-4">
        <mat-label>Client Name</mat-label>
        <input matInput formControlName="clientName" >
        <mat-error>Client Name is required!</mat-error>
    </mat-form-field>
 </div>
 <div fxLayout="row" fxLayoutAlign="start center" fxFlex="1 0 auto">
    <mat-form-field appearance="outline" fxFlex="100" class="pl-4">
        <mat-label>Description</mat-label>
        <input matInput formControlName="description" >
        <mat-error>Description is required!</mat-error>
    </mat-form-field>

  

 
 </div>
 </form>









<!-- / REACTIVE FORM EXAMPLE -->