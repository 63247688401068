 /* eslint-disable */
import { Component, Host, Input, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormGroupDirective, ControlContainer } from '@angular/forms';
import { DocumentColumns } from 'app/fake-db/form-helpers';
import { NewDocRegDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-document-delivery',
  templateUrl: './document-delivery.component.html',
  styleUrls: ['./document-delivery.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class DocumentDeliveryComponent implements OnInit {
    @Input() allDataFrequency: DocumentColumns[];
    @Input() allDeliveryFrequency: DocumentColumns[]; 
    @Input() document: NewDocRegDocument;
    form: FormGroup;
   
    compress = false;
  constructor( private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective) {
    
   }

  ngOnInit(): void {
      this.form = this.parentFor.form;
    
       // Reactive Form
      if (this.document.meta.compress === 'yes') {
        this.compress = true;
    }
      this.form.addControl('documentDelivery', this.formBuilder.group({
        dataEndDate: [this.document.meta.data_end_date],
        dataStartDate: [this.document.date_created],
        dataFrequency:  [this.document.meta.data_frequency],
        dataHistory: [this.document.meta.data_history],
        compress: [this.compress],
        deliveryStartDate: [this.document.meta.delivery_start_date],
        deliveryEndDate: [this.document.meta.delivery_end_date],
        deliveryFrequency:  [this.document.meta.delivery_frequency]
    }));

  }
}
