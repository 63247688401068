/* eslint-disable */
import { SrayApps } from './app-versions';
import {DataField} from './data-fields';
export interface DocumentData {
 doc_id: string;
 job_id: string;
 id: string;
 document: Document;
 pubsub_id: string;
 trace_id: string;
 created_at: string;
}
export interface Document {
    name: string;
    id: string;
    meta: {
        template?: string;
        fill_name?: string | undefined;
        file_na?: string;
        client_name: string;
        delivery_frequency?: string;
        data_frequency?: string;
        columns?: string[];
        separator?: string;
        dev_sray_service?: string;
        sray_version?: number;
    };
    description?: string;
}

export interface DocRegDocument {

        id: string;
        name: string;
        description: string;
        meta: {
            client_name: string;
            columns?: string;
            data_frequency?: string;
            delivery_frequency?: string;
            delivery_start_date?: string | Date;
            dev_sray_service?: string;
            file_name?: string;
            fill_na?: string;
            separator?: string;
            split_by_rowcount?: string;
            sray_version?: string;
            template?: string;
        };
        tags?: string[];
        author?: string;
        active: boolean;
        date_created?: Date;
        date_modified?: Date;

}

export interface NewDocRegDocument {

    id?: string;
    name: string;
    description: string;
    meta: {
        client_name: string;
        coverage_fields?: CoverageField[];
        data_fields?: DataField[];
        data_frequency?: string;
        delivery_frequency?: string;
        delivery_start_date?: string | Date;
        delivery_end_date?: string | Date;
        data_end_date?: string;
        data_start_date?: string;
        data_history?: string;
        dev_sray_service?: string;
        file_name?: string;
        fill_na?: string;
        separator?: string;
        split_by_rowcount?: string;
        sray_version?: string;
        template?: string;
        compress?: string;
    };
    tags?: string[];
    author?: string;
    active?: boolean;
    date_created?: Date | string;
    date_modified?: Date | string;

}
export interface PortfolioDocument {
 id?: string;
 name: string;
 description: string;
 meta: {
    benchmark?: string;
    client_name: string;
    db_api?: string,
    data_frequency?: string;
    delivery_frequency?: string;
    delivery_start_date?: string | Date;
    delivery_end_date?: string | Date;
    data_end_date?: string | Date;
    data_start_date?: string | Date;
    data_history?: string;
    dev_sray_service: string;
    file_name?: string;
    id_field: string,
    new_pf: boolean,
    portfolio?: string;
    portfolio_label?: string;
    sray_version?: string;
    sray: {
        version_string?: string;
        app_versions?: {}
    };
    temperature_version?: string;
    template?: string;
    with_catholic?: string;
    with_features?: string;
    with_temperature?: string;
 };
 tags?: string[];
 author?: string;
 active?: boolean;
 date_created?: Date | string;
 date_modified?: Date | string;
}
export interface CoverageField {
    app_name: string;
    app_version: number[];
    name: string;
}

export interface FormPart1 {
    name?: string;
    description?: string;
    clientName?: string;
    productType?: string;
}
export interface PFormPart1 {
    name?: string;
    description?: string;
    clientName?: string;
    srayApps?: SrayApps[];
}

export interface FormPart2 {
    identifiers?: string[];
    temperatureE?: string[];
    preferences?: string[];
    catholicPreferences?: string[];
    features?: string[];
    gcEsg?: string[];
    supplementalInfo?: [];
}
export interface PFormPart2 {
    identifiers?: string;
    portfolio: string;
    portfolioLabel: string;
    benchmark: string;
    benchmarkLabel: string;
    snapshots: string[];
}


export interface FormPart3 {
    fileName?: string;
    fileType: string;
    splitByRowCount?: string;
    separator?: string;
    quoting?: string;
    nullRepresentation?: string;
    sray_version?: string;
}
export interface PFormPart3 {
    fileName?: string;
    sray_version?: string;
}
export interface FormPart4 {
    dataEndDate?: string;
    dataStartDate?: string;
    dataFrequency?: string;
    dataHistory?: string;
    compress?: boolean;
    deliveryStartDate?: string;
    deliveryEndDate?: string;
    deliveryFrequency?: string;
}
export interface PFormPart4 {
    deliveryStartDate?: string;
    deliveryEndDate?: string;
    deliveryFrequency?: string;
    dataEndDate?: string;
    dataStartDate?: string;
}
export interface ExposedFormObject  {
    primaryInfo?: {
        name?: string;
        description?: string;
        clientName?: string;
    };
    productInfo?: {
        identifiers?: string[];
        gcEsg?: string[];
        preferences?: string[];
        temperatureE?: string[];
        catholicPreferences?: string[];
        supplementalInfo?: string[];
        features?: string[];
    };
    documentFormat?: {
        fileName?: string;
         splitByRowCount?: string;
         separator?: string;
         quoting?: string;
         nullRepresentation?: string;
         sray_versionray?: string;
    };
    documentDelivery?: {
        dataEndDate?: Date | string;
        dataStartDate?: Date | string;
        dataFrequency?: string;
        dataHistory?: string;
        compress?: string | boolean;
        deliveryStartDate?: Date | string;
        deliveryEndDate?: string;
        deliveryFrequency?: string;
    };
}
export interface ExposedPortfolioFormObject {
    primaryPortfolioInfo?: {
        name?: string;
        description?: string;
        clientName?: string;
    };
    portfolioInfo?: {
        benchmark?: string;
        db_api?: string;
        file_name?: string;
        id_field?: string;
        portfolio?: string;
        portfolio_label: string;
        sray_version?: string;
    };
    portfolioDelivery?: {
        app_versions?: {};
        version_string?: string;
        temperature_version?: string;
        template?: string;
        with_catholic?: string;
        with_features?: string;
        with_temperature?: string;
        delivery_frequency?: string;
        delivery_start_date?: string;
    };
}


export const authToken = 'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IlJEbEdORVF6UkRFNE56Z3lRa013T1RCRlF6aENRalJHUVRJNU9UTkJOMEkwTlVaQ1JURkRRdyJ9.eyJpc3MiOiJodHRwczovL2FyYWJlc3F1ZTIuYXV0aDAuY29tLyIsInN1YiI6ImF1dGgwfDVmMTZiMTU3ZDExNDU5MDAxM2JkM2JhNiIsImF1ZCI6WyJodHRwczovL3NyYXkuYXJhYmVzcXVlLmNvbS9hcGkvYXV0aDAiLCJodHRwczovL2FyYWJlc3F1ZTIuYXV0aDAuY29tL3VzZXJpbmZvIl0sImlhdCI6MTYwNjIzMDQ1MSwiZXhwIjoxNjA2MzE2ODUxLCJhenAiOiJMMWtPMnYyMVZYS2RDUTV2bGRRSHkwajI1eGk0SUc1TyIsInNjb3BlIjoib3BlbmlkIHByb2ZpbGUgZW1haWwgYWRkcmVzcyBwaG9uZSIsImd0eSI6InBhc3N3b3JkIn0.yHCquQPs7UVydaEuj42JiIkRng9POGhRPCwL3UWqhFmUW9sdSWCJTKIB8cFBU98pQKx7ckGHrL9ny07vlQIhebXEtm0NDtlWlrKl3ZxjhflV_50LsDT_LHgouqd_8DP7ooTv1vlA7uBnjlIoor36ktoCQjtySGjx6A5ncSJLv7EMz53dfifYkZp_OSfZTMsfR0Udu9ojuiiiSqzOPJk7F50VGbBZBDUaxGrgM8e8F_2KEa6ioUMw_E5qgWnVZD6ec86C8vfrJYcAupRsB87vpRNf4EjbNSpzHNDQfz24jpt1mGKMQlTDQBpR_t2IfUefEsAEc2XvGytekLRUzWnDdA';
