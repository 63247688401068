import { Component, Host, Input, OnInit } from '@angular/core';
import { ControlContainer, FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';
import { PortfolioDocument } from 'app/fake-db/real-doc';

@Component({
  selector: 'app-portfolio-document-info',
  templateUrl: './portfolio-document-info.component.html',
  styleUrls: ['./portfolio-document-info.component.scss'],
  viewProviders: [{provide: ControlContainer, useExisting: FormGroupDirective}]
})
export class PortfolioDocumentInfoComponent implements OnInit {
    @Input() portfolioDoc: PortfolioDocument;
    form: FormGroup;
    fgd: FormGroupDirective;
    portfolioInfo: any;
  constructor(private formBuilder: FormBuilder, @Host() private parentFor: FormGroupDirective) { }

  ngOnInit(): void {
    this.form = this.parentFor.form;
   
   
       // Reactive Form
    this.form.addControl('portfolioInfo', this.formBuilder.group(
        {
        
            benchmark: [this.portfolioDoc.meta.benchmark, Validators.required],
            dbApi: [this.portfolioDoc.meta.db_api, Validators.required],
            fileName: [this.portfolioDoc.meta.file_name, Validators.required],
            idField: [this.portfolioDoc.meta.id_field, Validators.required],
            portfolio: [this.portfolioDoc.meta.portfolio, Validators.required],
            portfolioLabel: [this.portfolioDoc.meta.portfolio_label, Validators.required],
            srayVersion: [this.portfolioDoc.meta.sray_version, Validators.required],
        }
    ));

  }

}
