 /* eslint-disable */
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { allCatholicDataFields } from 'app/fake-db/catholic';
import { allAddtionalDataFields, DataField } from 'app/fake-db/data-fields';
import { allGcEsgDataFields } from 'app/fake-db/esg-gc';
import { allFeatureDataFields } from 'app/fake-db/features';
import { allPreferencesDataFields } from 'app/fake-db/preferences';
import { authToken, ExposedFormObject, NewDocRegDocument } from 'app/fake-db/real-doc';
import { allTemperatureDataFields } from 'app/fake-db/temperature';
import { flatMap, tap } from 'lodash';
import { from, Observable, throwError } from 'rxjs';
import { catchError, delay, mergeMap, retryWhen, take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EditDocumentService {
    doc: NewDocRegDocument;
    authToken: string = sessionStorage.getItem('token');
    constructor(private http: HttpClient) { }

  editDocument(document: NewDocRegDocument, form: ExposedFormObject): Observable<any> {
    const httpOptions = { 
        headers: new HttpHeaders ({
            'Content-Type': 'application/json',
            Authorization: `Bearer ${this.authToken}`
        })
    };
    return from(this.combineDocObjects(document, form))
   .pipe(
       mergeMap((data: NewDocRegDocument) => this.http.patch(`http://127.0.0.1:8080/doc-registry/api/v1/document/${document.id}`, data, httpOptions)),
       retryWhen(errors => errors.pipe(delay(1000), take(2))),
      catchError(this.handleError)
   );
  
    
   } 
   private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // Return an observable with a user-facing error message.
    return throwError(
      'Something bad happened; please try again later.');
  }

 private appendPrimaryInformation(document: NewDocRegDocument, form: ExposedFormObject): Promise<NewDocRegDocument> {
    
    // eslint-disable-next-line no-shadow
    return new Promise((resolve) => {
        const primaryInfo = form.primaryInfo;
        if (primaryInfo.name) {
            
            document.name = form.primaryInfo.name;
        }
        if (primaryInfo.description) {
    
            document.description = form.primaryInfo.description;
        }
        if (primaryInfo.clientName) {

            document.meta.client_name = primaryInfo.clientName;
        }
        resolve(document);
    });

}

private appendProductInformation(appended: NewDocRegDocument, form: ExposedFormObject): Promise<NewDocRegDocument> {

    // eslint-disable-next-line no-shadow
    return new Promise((resolve) => {
        const products = form.productInfo;
        const dataFields: DataField[] = [];
        if (products.gcEsg.length > 0) {
                products.gcEsg.forEach(e => {
                    dataFields.push(allGcEsgDataFields[e]); 
                 });
            }
        if (products.identifiers.length > 0){
                products.identifiers.forEach(e => {
                    dataFields.push(allAddtionalDataFields[e]); 
                 });
            }
        if (products.preferences.length > 0){
                products.preferences.forEach(e => {
                   
                    if (e === 'con_wea_inv') {
                        dataFields.push(allPreferencesDataFields['pf_con_wea_inv']);
                    }
                    else if (allPreferencesDataFields[e].length > 0) {
                        dataFields.push(...allPreferencesDataFields[e]);
                    }
                     else {
                        dataFields.push(allPreferencesDataFields[e]);
                    }
                   
                 });
            }
        if (products.temperatureE.length > 0){
                products.temperatureE.forEach(e => {
                  
                    dataFields.push(allTemperatureDataFields[e]); 
                 });
            }
        if (products.catholicPreferences.length > 0){
                products.catholicPreferences.forEach(e => {
                    dataFields.push(allCatholicDataFields[e]); 
                 });   
            }
        if (products.supplementalInfo.length > 0){
                products.supplementalInfo.forEach(e => {
                    dataFields.push(allAddtionalDataFields[e]); 
                 });
            }
            
              
        if (products.features.length > 0){
                products.features.forEach(e => {
                    dataFields.push(allFeatureDataFields[e]); 
                 });
            }
        if (dataFields.length > 0) {
            appended.meta.data_fields = dataFields;     
        } else {
            appended.meta.data_fields = [];
        }
        resolve(appended);
          
    });
}
private appendDocumentFormatInformation(appended: NewDocRegDocument, form: ExposedFormObject): Promise<NewDocRegDocument> {
    // eslint-disable-next-line no-shadow
    return new Promise(resolve => {
        const documentFormat = form.documentFormat;
        if (documentFormat.fileName) {
            appended.meta.file_name = documentFormat.fileName;
        }
        if (documentFormat.nullRepresentation) {
            appended.meta.fill_na = documentFormat.nullRepresentation;
        }
        if (documentFormat.quoting) {
        }
        if (documentFormat.separator) {
            appended.meta.separator = documentFormat.separator;
        }
        if (documentFormat.splitByRowCount) {
            appended.meta.split_by_rowcount = documentFormat.splitByRowCount;
        }
        if (documentFormat.sray_versionray) {
            appended.meta.sray_version = documentFormat.sray_versionray;
        }
        resolve(appended);
    });
}
private appendDocumentDeliveryInformation(appended: NewDocRegDocument, form: ExposedFormObject): Promise<NewDocRegDocument> {
    return new Promise(resolve => {
        const documentDelivery = form.documentDelivery;
        if (documentDelivery.compress) {
            if (documentDelivery.compress === true) {
               
                appended.meta.compress = 'yes';
            } else{
                appended.meta.compress = 'no';
            }
            
        }
        if (documentDelivery.dataEndDate) {
        }
        if (documentDelivery.dataFrequency) {
            appended.meta.data_frequency = documentDelivery.dataFrequency;
        }
        if (documentDelivery.dataHistory) {
        }
        if (documentDelivery.dataStartDate) {
        }
        if (documentDelivery.deliveryEndDate) {

        }
        if (documentDelivery.deliveryFrequency) {
            appended.meta.delivery_frequency = documentDelivery.deliveryFrequency;
        }
        if (documentDelivery.deliveryStartDate) {
            appended.meta.delivery_start_date = documentDelivery.deliveryStartDate;
        }
        resolve(appended);
        });
}
private deleteFields(appended: NewDocRegDocument): Promise<NewDocRegDocument> {
    return new Promise(resolve => {
        delete appended['active'];
        delete appended.author;
        delete appended.date_created;
        delete appended.date_modified;
        resolve(appended);

    });
}
private async combineDocObjects(document: NewDocRegDocument, form: ExposedFormObject): Promise<NewDocRegDocument> {
  
    const primaryInfo = await this.appendPrimaryInformation(document, form);
    const productInfo = await this.appendProductInformation(primaryInfo, form);
    const documentFormat = await this.appendDocumentFormatInformation(productInfo, form);
    const documentDelivery = await this.appendDocumentDeliveryInformation(documentFormat, form);
    const finalDocument = await this.deleteFields(documentDelivery).then(data => this.doc = data);
    return  finalDocument;
}

}
