
<!-- HORIZONTAL STEPPER EXAMPLE -->
<mat-horizontal-stepper  [linear]="true">
    <mat-step [stepControl]="portfolioFormStep1">

        <form fxLayout="column" [formGroup]="portfolioFormStep1">

            <ng-template matStepLabel>Create New Portfolio Document</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Document name</mat-label>
                    <input matInput formControlName="name" >
                    <mat-error>Document  name is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Description</mat-label>
                    <input matInput formControlName="description" >
                    <mat-error>Description is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Client Name</mat-label>
                    <input matInput formControlName="clientName" >
                    <mat-error>Client Name is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>SRay Apps</mat-label>
                    <mat-select formControlName="srayApps" multiple>
                        <mat-option *ngFor="let app of srayApps" [value]="app">
                            {{app.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-error>Sray Apps are required!</mat-error>
                </mat-form-field>


               
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>
    <mat-step [stepControl]="portfolioFormStep2" >

        <form fxLayout="column" [formGroup]="portfolioFormStep2">

            <ng-template matStepLabel>Portfolio Details</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Portfolio Name</mat-label>
                    <input matInput formControlName="portfolio" >
                    <mat-error>Portfolio  name is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Portfolio Label</mat-label>
                    <input matInput formControlName="portfolioLabel" >
                    <mat-error>Portfolio Label is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Benchmark</mat-label>
                    <input matInput formControlName="benchmark" >
                    <mat-error>Benchmark is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Benchmark Label</mat-label>
                    <input matInput formControlName="benchmarkLabel" >
                    <mat-error>Benchmark Label is required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Snapshots</mat-label>
                    <mat-select formControlName="snapshots" multiple>
                        <mat-option *ngFor="let snap of snapshots" [value]="snap.value">
                            {{snap.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-error>Identifiers are required!</mat-error>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Identifiers</mat-label>
                    <mat-select formControlName="identifiers">
                        <mat-option *ngFor="let detail of identifiers" [value]="detail.value">
                            {{detail.viewValue}}
                          </mat-option>
                    </mat-select>
                    <mat-error>Identifiers are required!</mat-error>
                </mat-form-field>

              

            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                    Previous
                </button>
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>

    <mat-step [stepControl]="portfolioFormStep3">

        <form fxLayout="column" [formGroup]="portfolioFormStep3">

            <ng-template matStepLabel>Document Delivery</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>File Name</mat-label>
                    <input matInput formControlName="fileName" >
                    <mat-error> File Name is required!</mat-error>
                </mat-form-field>

               
                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>SRay Version</mat-label>
                    <input matInput formControlName="sray_version">
                    <mat-error> Version is required!</mat-error>
                </mat-form-field>
            </div>

            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                    Previous
                </button>
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>
    <mat-step [stepControl]="portfolioFormStep4">

        <form fxLayout="column" [formGroup]="portfolioFormStep4">

            <ng-template matStepLabel>Document Delivery</ng-template>

            <div fxFlex="1 0 auto" fxLayout="column">
              

           
                <mat-form-field appearance="outline"  fxFlex="100">
                    <mat-label>Delivery Start Date</mat-label>
                    <input matInput [matDatepicker]="deliveryStartDate" name="deliveryStartDate" formControlName="deliveryStartDate">
                    <mat-datepicker-toggle matSuffix [for]="deliveryStartDate" ></mat-datepicker-toggle>
                    <mat-datepicker  #deliveryStartDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline"  fxFlex="100">
                    <mat-label>Delivery End Date</mat-label>
                    <input matInput [matDatepicker]="deliveryEndDate" name="deliveryEndDate" formControlName="deliveryEndDate">
                    <mat-datepicker-toggle matSuffix [for]="deliveryEndDate"></mat-datepicker-toggle>
                    <mat-datepicker  #deliveryEndDate></mat-datepicker>
                </mat-form-field>

                <mat-form-field appearance="outline" fxFlex="100">
                    <mat-label>Delivery Frequency</mat-label>
                    <mat-select formControlName="deliveryFrequency">
                        <mat-option *ngFor="let del of deliveryFrequency" [value]="del.value">
                            {{del.viewValue}}
                          </mat-option>
                    </mat-select>
                </mat-form-field>


            </div>

           

            <div fxLayout="row" fxLayoutAlign="center center">
                <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                    Previous
                </button>
                <button mat-raised-button matStepperNext type="button" color="accent">
                    Next
                </button>
            </div>

        </form>

    </mat-step>

    <mat-step>

        <ng-template matStepLabel>Done</ng-template>

        <div class="h2 m-16" fxLayout="row" fxLayoutAlign="center center">
            Submit form to create a new Document
        </div>

        <div fxLayout="row" fxLayoutAlign="center center">
            <button class="mr-8" mat-raised-button matStepperPrevious type="button" color="accent">
                Previous
            </button>
            <button mat-raised-button type="button" color="accent"  mat-dialog-close (click)="finishHorizontalStepper()">
                Finish
            </button>
        </div>

    </mat-step>

</mat-horizontal-stepper>
<!-- / HORIZONTAL STEPPER EXAMPLE -->
